// graphqlQueries.ts
import { gql } from '@apollo/client';

// Define all your queries and mutations here
export const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      success
      message
      token
      user {
        id
        username
      }
    }
  }
`;

// You can add other queries or mutations here
