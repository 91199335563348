import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

const otherPageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/home2",
  //   name: "Home Demo 2",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/home3",
  //   name: "Home Demo 3",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/page-collection",
  //   name: "Collection page",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/page-search",
  //   name: "Search page",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/page-author",
  //   name: "Author Profile",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/forgotpassword",
  //   name: "Forgot passwords",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/newpassword",
  //   name: "New password",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/verify",
  //   name: "Verify",
  // },
  {
    id: ncNanoId(),
    href: "/explore-domains",
    name: "Explore Domains",
  },

  // {
  //   id: ncNanoId(),
  //   href: "/about",
  //   name: "Other Pages",
  //   type: "dropdown",
  //   children: [
  {
    id: ncNanoId(),
    href: "/roadmap",
    name: "Roadmap",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "Team",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact us",
  },
  {
    id: ncNanoId(),
    href: "/interoperability",
    name: "Interoperability",
  },
  {
    id: ncNanoId(),
    href: "/documentation",
    name: "Documentation",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/payment2",
  //   name: "Payment",
  // },

  // {
  //   id: ncNanoId(),
  //   href: "/update-profile",
  //   name: "Wallet setting",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/subscription",
  //   name: "Subscription",
  // },
  // ],
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/blog",
  //   name: "Blog Page",
  //   type: "dropdown",
  //   children: [
  //     {
  //       id: ncNanoId(),
  //       href: "/blog",
  //       name: "Blog Page",
  //     },
  //     {
  //       id: ncNanoId(),
  //       href: "/blog-single",
  //       name: "Blog Single",
  //     },
  //   ],
  // },
];

export const NAVIGATION_DEMO_2: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Discover",
    type: "dropdown",
    children: otherPageChildMenus,
  },
  // {
  //   id: ncNanoId(),
  //   href: "/page-upload-item",
  //   name: "Help center",
  // },
];
