import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imagePng from "images/creative1.jpg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import cryptoAnim from "./cryptoAnim.json"
import lottie from "lottie-web";

export interface SectionHero2Props {
  children?: React.ReactNode;
  className?: string;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "", children }) => {
  let navigate = useNavigate();
  const [domain, changeDomain] = useState("");
  const [visible, setVisible]= useState('none');


    React.useEffect(() => {
      const container = document.querySelector("#react-logo");
      if (container) {
        lottie.loadAnimation({
          container,
          animationData: cryptoAnim,
          loop: true,
          autoplay: true,
        });
      }
      
    }, []);

  function HandleEnter(event: any) {
    if (event.key === "Enter") {
      if(domain===""){
       setVisible('block');
      }
      else {
        navigate("/page-search", { state: { domain: domain.split(" ").join("") } });
           }
    }
  }
  function HandleClick () {
    {
      if(domain===""){
        setVisible('block');
       }
      else
      {
        navigate("/page-search", { state: { domain: domain } });
      }
    }
  }
  function handleChange(event: any) {
    changeDomain(event.target.value);
  }
  return (
    <div
      className={`nc-Secti  onHero2 flex flex-col-reverse lg:flex-col relative ${className}`}
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div 
        // className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10  lg:pb-36 xl:pb-60 xl:pr-14 lg:mr-10 xl:mr-0"
        className="flex justify-center flex-col space-y-8 flex-shrink-0 lg:w-1/2 sm:space-y-10 lg:mr-10 xl:mr-0 xl:mb-[6rem]"
        >
          <h2 className="font-semibold text-4xl md:text-5xl xl:text-6xl !leading-[114%] ">
          Own your Web3 domains
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
        for your journey through THE NEXT INTERNET<br /><b>Starts at $5</b>{" "}
            {/* Creative your NTFs and sell them */}
          </span>
          <div className="w-full sm:block pb-5 flex-grow ">
            <div className="relative">
              <Input
                onKeyDown={HandleEnter}
                value={domain}
                onChange={handleChange}
                type="search"
                placeholder="Search your domain"
                className="pr-16"
                sizeClass="h-[42px] pl-4 py-6"
              />
              <span className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500">
                <button onClick={HandleClick}>
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                </button>
              </span>
              {/* <input type="submit" hidden value="" /> */}
            </div>
          </div>
          {/* <ButtonPrimary>
            <span>Start your search</span>
            <span>
              <svg className="w-5 h-5 ml-2.5" viewBox="0 0 24 24" fill="none">
                <path
                  d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 22L20 20"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </ButtonPrimary> */}
          <span className="p-0 m-0 text-red-400" style={{display:visible}}>Enter a valid domain</span>
        </div>
        <div className="flex-grow flex justify-center ">
          <img className="w-[80%] xl:h-[550px] sm:h-[400px]  rounded-3xl" src={imagePng} alt="hero"  />
          {/* <div id="react-logo" /> */}
        </div>
      </div>

      {/* <div className="z-10 mb-12 lg:mb-0 lg:-mt-20 xl:-mt-48 w-full">
        <HeroSearchForm />
      </div> */}
    </div>
  );
};

export default SectionHero2;
