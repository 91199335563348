import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import NcModal from "shared/NcModal/NcModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import QrCodeImg from "images/qr-code.png";
import stripe from "images/stripe.svg";
import razorpay from "images/razorpay.svg";
import metamask from "../images/metamask_img.png";
import walletconnectImg from "images/walletconnect.webp";
import walletlinkImg from "images/walletlink.webp";
import fortmaticImg from "images/fortmatic.webp";
import { useNavigate } from "react-router-dom";
import { UserGlobalContext } from "user";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import PageLogin from "containers/PageLogin/PageLogin";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import qs from "query-string";
import Cookies from "universal-cookie";
import Web3 from "web3";
import { MetaMaskSDK } from "@metamask/sdk";
import loading from "../images/loader.gif";
import airdrop from "../images/airdrop.png";
const MMSDK = new MetaMaskSDK({
  injectProvider: false,
  dappMetadata: { name: "United Protocol", url: "https://www.unitedprotocol.org" },
});
window.ethereum = MMSDK.getProvider();
export interface PageConnectWalletProps {
  className?: string;
}
const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continue with Google",
    href: "http://localhost:5000/auth/google2",
    icon: googleSvg,
  },
];
const plans = [
  {
    id: 1,
    name: "Stripe",
    img: stripe,
    api: "stripe",
  },
  {
    id: 2,
    name: "Razorpay",
    img: razorpay,
    api: "razorpay",
  },

  // {
  //   name: "Metamask",
  //   img: metamask,
  //   api: "metamask",
  // },

  // {
  //   name: "Walletlink",
  //   img: walletlinkImg,
  // },
  // {
  //   name: "Fortmatic",
  //   img: fortmaticImg,
  // },
];

const plans1 = [
  {
    name: "AirDrop",
    img: airdrop,
    api: "airdrop",
  },
];
const plans2 = [
  {
    name: "Metamask",
    img: metamask,
  },
];

const PageConnectWallet: FC<PageConnectWalletProps> = ({ className = "" }) => {
  const cookies = new Cookies();
  let navigate = useNavigate();
  const { LoggedIn } = UserGlobalContext();
  const { state } = useLocation();
  let exdomain = "";
  let excoupon = "No_Coupon";
  if (state != null) {
    exdomain = state.domain;
    if (state.coupon != "") {
      excoupon = state.coupon;
      console.log("exco", excoupon);
    }
  } else {
    exdomain = decodeURI(window.location.search)
      .split("?")[1]
      .split("&")[0]
      .split("=")[1];
    if (
      decodeURI(window.location.search)
        .split("?")[1]
        .split("&")[1]
        .split("=")[1] != ""
    ) {
      excoupon = decodeURI(window.location.search)
        .split("?")[1]
        .split("&")[1]
        .split("=")[1];
    }
  }
  console.log(exdomain);
  console.log(excoupon);
  const [domain, changeDomain] = useState(exdomain);
  const [website, changeWebsite] = useState("");
  const [coupon, changeCoupon] = useState(excoupon);
  const [cost, changeCost] = useState(-1);
  const [verifyLoading, setVerifyLoading] = useState("none");
  useEffect(() => {
    setVerifyLoading("block");
    Axios.get("http://localhost:5000/metadata", {
      withCredentials: true,
      timeout: 5000,
    })
      .then((response) => {
        changeWebsite(response.data.website);
      })
      .catch((err) => console.log("metadata err", err));
    async function getCost() {
      let cost2 = 0;
      await Axios.get("http://localhost:5000/search/" + domain, {
        timeout: 5000,
      })
        // eslint-disable-next-line no-loop-func
        .then((response) => {
          console.log("search=", response.data);
          cost2 += response.data.cost;
        })
        .catch(function (err) {
          console.log(err);
        });
      changeCost(cost2);
      await Axios.get("http://localhost:5000/coupon/" + coupon, {
        timeout: 5000,
      })
        .then((response) => {
          if (response.data !== "Invalid Code") {
            console.log("IN");
            if (response.data[0].amount !== 0) {
              console.log(response.data[0].amount);
              cost2 -= response.data[0].amount;
              changeCost(cost2);
              // setVerifyLoading("none");
            } else {
              console.log(response.data[0].percentage);
              cost2 -= (cost2 * response.data[0].percentage) / 100;
              changeCost(cost2);
              // setVerifyLoading("none");
            }
          }
        })
        .catch((err) => {
          console.log("coupon", err);
        });
    }
    getCost();
    setVerifyLoading("none");
  }, [website, coupon, domain]);
  const [priceofBNB, setPriceBnb] = useState(0.0);

  function hexEncode(domain3: any) {
    var result = "";
    for (var i = 0; i < domain3.length; i++) {
      result += domain3.charCodeAt(i).toString(16);
    }
    return result;
  }
  async function register() {
    if (window.ethereum !== undefined && window.ethereum.isMetaMask === true) {
      const currentChainId = await window.ethereum.request({
        method: "eth_chainId",
      });
      if (currentChainId !== "0x38") {
        await window.ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x38",
                chainName:
                  "BNB Smart Chain (previously Binance Smart Chain Mainnet)",
                rpcUrls: ["https://bsc-dataseed.binance.org/"],
              },
            ],
          })
          .catch((err) => {
            alert(
              "Please change your chain to Binance Smart Chain in Metamask for registering!!"
            );
            console.log(err);
          });
      }
      await Axios.get("https://api.binance.com/api/v3/avgPrice?symbol=BNBUSDT")
        .then((response) => {
          // console.log(response.data);
          setPriceBnb(response.data.price);
        })
        .catch((err) => {
          console.log(err);
        });
      const accounts = await window.ethereum
        .request({ method: "eth_accounts" })
        .catch((err) => {
          if (err.code === 4001) {
            console.log("Please connect to MetaMask.");
          } else {
            console.error(err);
          }
        });
      if (accounts !== null && accounts !== undefined) {
        try {
          let value = "0";
          if (cost !== 0) {
            value = (((cost + 10) / priceofBNB) * Math.pow(10, 18)).toString(
              16
            );
          } else {
            value = "0";
          }
          // console.log(value);
          const params = [
            {
              from: accounts[0 as keyof typeof accounts],
              to: "0x051cC197F17A40edf16d67e3207A18a2E81AE238",
              value: value,
              gas: "0x5028",
              data: "0x" + hexEncode(domain),
              chainId: "56",
              chainName:
                "BNB Smart Chain (previously Binance Smart Chain Mainnet)",
              nativeCurrency: {
                name: "Binance Chain Native Token",
                symbol: "BNB",
                decimals: "18",
              },
              rpcUrls: ["https://bsc-dataseed.binance.org/"],
              blockExplorerUrls: ["https://bscscan.com/"],
            },
          ];
          await window.ethereum
            .request({ method: "eth_sendTransaction", params })
            .then(async (result: any) => {
              console.log("txnHash is " + result.transactionHash);
              await Axios.post("http://localhost:5000/metamask", {
                domain: domain,
                website: website,
                coupon: coupon,
                hash: result.transactionHash,
              })
                .then((response) => {
                  console.log(response.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err: any) => {
              console.log(err);
            });
        } catch (e) {
          console.log("payment fail!");
          console.log(e);

          alert(
            "Can't connect MetaMask. Please check MetaMask. Try to open the extension once and try again."
          );
        }
      } else {
        alert("No account found!!");
      }
    } else {
      alert("Please download Metamask Extension and try again!!");
    }
  }
  function HandleClick() {
    navigate("/airdrop", { state: { domain: domain, coupon: coupon, cost: state.cost } });
    // navigate("/airdrop", { state: { domain: domain } });
  }
  console.log("domain ", state.domain);
  console.log("coupon ", state.coupon);
  console.log("cost ", state.cost);
  const [showModal, setShowModal] = useState(false);
  if (LoggedIn === false) {
    return (
      <div
        className={`nc-PageConnectWallet ${className}`}
        data-nc-id="PageConnectWallet"
      >
        <Helmet>
          <title>Login || United Protocol</title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Login
          </h2>
          <div className="max-w-md mx-auto space-y-6">
            <div className="grid gap-3">
              {loginSocials.map((item, index) => (
                <a
                  key={index}
                  href={item.href + "/" + domain + "/" + coupon}
                  className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                >
                  <img
                    className="flex-shrink-0"
                    src={item.icon}
                    alt={item.name}
                  />
                  <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                    {item.name}
                  </h3>
                </a>
              ))}
            </div>
            {/* OR */}
            <div className="relative text-center">
              <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                OR
              </span>
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div>
            {/* FORM */}
            <form
              className="grid grid-cols-1 gap-6"
              action={
                "https://apibnb.unitedprotocol.topay/" + domain + "/" + coupon
              }
              method="post"
            >
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>
                <Input
                  name="username"
                  type="email"
                  placeholder="example@example.com"
                  className="mt-1"
                />
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                  <Link to="/forgot-pass" className="text-sm text-green-600">
                    Forgot password?
                  </Link>
                </span>
                <Input name="password" type="password" className="mt-1" />
              </label>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </form>

            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              New user? {` `}
              <Link to="/signup" className="text-green-600">
                Create an account
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`nc-PageConnectWallet ${className}`}
        data-nc-id="PageConnectWallet"
      >
        <Helmet>
          <title>Payment || United Protocol</title>
        </Helmet>
        <div className="container">
          <div className="my-12 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
            {/* HEADING */}
            <div className="max-w-2xl">
              <h2 className="text-3xl sm:text-4xl font-semibold">Payment</h2>
              <img
                src={loading}
                style={{ display: verifyLoading }}
                className="w-10 h-10"
              ></img>
              {/* <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              Connect with one of our available wallet providers or create a new
              one.
            </span> */}
            </div>
            <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
            <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
              <div className="space-y-3">
                {plans1.map((plan) => (
                  <form
                  // action={"http://localhost:5000/" + plan.api}
                  // method="POST"
                  >
                    <button
                      type="submit"
                      key={plan.name}
                      // onClick={() => setShowModal(true)}
                      onClick={HandleClick}
                      typeof="button"
                      tabIndex={0}
                      style={{ width: "100%" }}
                      className="relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border
                border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex
                focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800
                dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200"
                    >
                      <input
                        required
                        type="hidden"
                        name="domain"
                        value={domain}
                      />
                      <input
                        required
                        type="hidden"
                        name="website"
                        value={website}
                      />
                      <input
                        required
                        type="hidden"
                        name="coupon"
                        value={coupon}
                      />
                      <input
                        required
                        type="hidden"
                        name="description"
                        value={"Payment for buying the domain " + domain}
                      />
                      <div className="flex items-center w-full">
                        <NcImage
                          src={plan.img}
                          containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
                        />
                        <div
                          className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl `}
                        >
                          {plan.name}
                        </div>
                      </div>
                    </button>
                  </form>
                ))}
                {plans.map((plan) => (
                  <form
                    action={"http://localhost:5000/" + plan.api}
                    method="POST"
                  >
                    <button
                      type="submit"
                      key={plan.name}
                      // onClick={() => setShowModal(true)}
                      typeof="button"
                      tabIndex={0}
                      style={{ width: "100%" }}
                      className="relative pointer-events-none opacity-[0.5] rounded-xl hover:shadow-lg hover:bg-neutral-50 border
                border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex
                focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800
                dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200"
                    >
                      <input
                        required
                        type="hidden"
                        name="domain"
                        value={domain}
                      />
                      <input
                        required
                        type="hidden"
                        name="website"
                        value={website}
                      />
                      <input
                        required
                        type="hidden"
                        name="coupon"
                        value={coupon}
                      />
                      <input
                        required
                        type="hidden"
                        name="description"
                        value={"Payment for buying the domain " + domain}
                      />
                      <div className="flex items-center w-full">
                        <NcImage
                          src={plan.img}
                          containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
                        />
                        <div
                          className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl `}
                        >
                          {plan.name}
                        </div>
                      </div>
                    </button>
                  </form>
                ))}

                {plans2.map((plan) => (
                  <button
                    key={plan.name}
                    onClick={register}
                    typeof="button"
                    tabIndex={0}
                    style={{ width: "100%" }}
                    className="relative pointer-events-none opacity-[0.5] rounded-xl hover:shadow-lg hover:bg-neutral-50 border
                border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex
                focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800
                dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200"
                  >
                    {/* <input required type="hidden" name="domain" value={domain}/>
                <input required type="hidden" name="website" value={website}/>
                <input required type="hidden" name="coupon" value={coupon}/>
                <input required type="hidden" name="description" value={"Payment for buying the domain "+domain}/> */}
                    <div className="flex items-center w-full">
                      <NcImage
                        src={plan.img}
                        containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
                      />
                      <div
                        className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl `}
                      >
                        {plan.name}
                      </div>
                    </div>
                  </button>
                ))}
              </div>

              {/* ---- */}
              <div className="pt-2 ">
                <ButtonPrimary href={"/"} className="flex-1">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.5 12H3.67004"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="ml-2">Go Back Home</span>
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PageConnectWallet;
