import React, { FC, useState, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Navigation from "shared/Navigation/Navigation";
import { Link, useNavigate } from "react-router-dom";
import { DownloadIcon } from "@heroicons/react/solid";
import atc from "../../images/addtocart.svg";
import { UserGlobalContext } from "user";

export interface MainNav2LoggedProps { }

const MainNav2Logged: FC<MainNav2LoggedProps> = (props) => {
  const { cartItems } = UserGlobalContext();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()

  // const [cartCount, setCartCount] = useState(0);
  // const [array, setArray] = useState("");
  // var str = document.cookie.split(";");
  // const result: any = {};
  // for (let i in str) {
  //   const cur = str[i].split("=");
  //   result[cur[0]] = cur[1];
  // }
  // useEffect(()=>{
  //   setInterval(() => {
  //     var str = document.cookie.split(";");
  //     const result: any = {};
  //     for (let i in str) {
  //       const cur = str[i].split("=");
  //       result[cur[0]] = cur[1];
  //     }
  //     // console.log(JSON.parse(result[" cartItem"]).length);
  //     if (result[" cartItem"] != array) {
  //       setArray(
  //         JSON.parse(result[" cartItem"]) != null
  //           ? JSON.parse(result[" cartItem"])
  //           : ""
  //       );
  //     }
  //     if (array.length != cartCount) {

  //       setCartCount(array != null ? array.length : 0);
  //     }
  //   }, 100);
  // },[result[" cartItem"]])

  const handleOpen = () => {
    // setOpen(!open);
    navigate("/contact")
  };
  return (
    <div className={`nc-MainNav2Logged relative z-10 ${"onTop "}`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
          {/* <div className="hidden sm:block flex-grow max-w-xs">
            <form action="" method="POST" className="relative">
              <Input
                type="search"
                placeholder="Search items"
                className="pr-10 w-full"
                sizeClass="h-[42px] pl-4 py-3"
              />
              <span className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input type="submit" hidden value="" />
            </form>
          </div> */}
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-2">
            <Link className="px-8" to="/">
              <b>Domains</b>
            </Link>
            {/* <Link to="/thenextinternet" className="pr-6">
              <b>Documentation</b>
            </Link> */}
            <a href="https://docs.unitedprotocol.org/" className="pr-4">

              <b>Documentation</b>
            </a>
            <a href="https://www.unitedprotocol.org/" className="pr-4">

              <b>United Protocol</b>
            </a>
            <Link to="/contact" className="pr-6">
              <b>Contact</b>
            </Link>
            {/* <Navigation /> */}
            <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div>
            <div className="flex">
              <SwitchDarkMode />
              <div>
                <button
                  onClick={handleOpen}
                  
                  className="flex flex-row bg-[#0ac74c] hover:opacity-[0.9] text-black  py-2 px-4 rounded-full"
                  // style={{
                  //   background: "linear-gradient(264.28deg,#a5f1b6 -38.2%,#0ac74c 103.12%)"
                  // }}
                >
                  <DownloadIcon className="w-4 h-6" />
                  Download DamGo
                </button>
                {/* {open ? (
                  <div>
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        minWidth: "180px",
                        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                      }}
                      className="absolute z-1 mt-2 flex flex-col p-2  rounded-lg"
                    >
                      <Link
                        className="p-2 hover:bg-gray-100 rounded text-black text-sm"
                        to="https://drive.google.com/file/d/1nMr3xV-klH_6l0nI3ySXfEHP_BHoqKdA/view?usp=share_link"
                      >
                        Mac-Silicon
                      </Link>
                      <a
                        className="p-2 hover:bg-gray-100 rounded text-black text-sm"
                        href="/"
                      >
                        Mac-Intel
                      </a>
                      <a
                        className="p-2 hover:bg-gray-100 rounded text-black text-sm"
                        href="/"
                      >
                        Android
                      </a>
                      <a
                        className="p-2 hover:bg-gray-100 rounded text-black text-sm"
                        href="/"
                      >
                        Windows
                      </a>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )} */}
              </div>
              {/* <NotifyDropdown /> */}
            </div>
            {/* <div></div>
            // <ButtonPrimary
            //   href="/login"
            //   sizeClass="px-4 py-2 sm:px-5"
            // >
            //   Log in
            // </ButtonPrimary>
            // <ButtonPrimary
            //   href="/signup"
            //   sizeClass="px-4 py-2 sm:px-5"
            // >
            //  Sign up
            // </ButtonPrimary>
            // <div></div>*/}
            <AvatarDropdown />
          </div>
          <div className="relative inline-flex">
            <Link to="/cart">
              {" "}
              <img src={atc} className="w-10 "></img>
            </Link>
            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
              {cartItems}
            </div>
          </div>

          <div className="flex items-center space-x-3 xl:hidden">
            {/* <NotifyDropdown /> */}
            <AvatarDropdown />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2Logged;
