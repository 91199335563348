import react, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
export interface MydomainProps {
  domain?: string;
  price?: number;
  index?: number;
}
const Mydomain: FC<MydomainProps> = ({ domain = "", price, index }) => {
  let [categories] = useState(["domain", "index", "price"]);
  let navigate = useNavigate();
  function HandleClick() {
    navigate("/edit-domain-data", { state: { domain: domain } });
  }
  console.log("price", price);
  return (
    <tr className="dark:bg-neutral-800 bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
      <td className="px-6 py-4 dark:text-neutral-50 whitespace-nowrap text-sm font-medium text-gray-900">
        {index}
      </td>
      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        {domain}
      </td>
      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        ${price}
      </td>
      {/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        <div className="w-32 flex items-center justify-center px-2 py-1 mt-2 bg-yellow-100 rounded-full">
          <p className="text-sm leading-5 text-yellow-700">
            {domainData.type}
          </p>
        </div>
      </td> */}
      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        <ButtonPrimary onClick={HandleClick}>Edit</ButtonPrimary>
      </td>
    </tr>
  );
};
export default Mydomain;
