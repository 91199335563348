// import React, { FC, useEffect, useState } from "react";
// import facebookSvg from "images/Facebook.svg";
// import twitterSvg from "images/Twitter.svg";
// import googleSvg from "images/Google.svg";
// import { Helmet } from "react-helmet";
// import Input from "shared/Input/Input";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import { Link, useNavigate } from "react-router-dom";
// import {UserGlobalContext} from "user";
// export interface PageSignUpProps {
//   className?: string;
// }

// const loginSocials = [
//     // {
//     //   name: "Continue with Facebook",
//     //   href: "#",
//     //   icon: facebookSvg,
//     // },
//     // {
//     //   name: "Continue with Twitter",
//     //   href: "#",
//     //   icon: twitterSvg,
//     // },
//   {
//     name: "Continue with Google",
//     href: "http://localhost:5000/auth/google",
//     icon: googleSvg,
//   },
// ];

// const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
//   const { LoggedIn } = UserGlobalContext();
//   const navigate = useNavigate();
//   const [errorMessage, setErrorMessage] = useState("");
//   useEffect(()=>{
//     if(LoggedIn==true){
//       navigate("/account");
//     }
//   });

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();

//     const formData = new FormData(event.target as HTMLFormElement);
//     const urlSearchParams = new URLSearchParams();

//     // Convert FormData to URLSearchParams
//     formData.forEach((value, key) => {
//       urlSearchParams.append(key, value as string);
//     });

//     try {
//       const response = await fetch("http://localhost:5000/register", {
//         method: "POST",
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: urlSearchParams.toString(),  // Convert URLSearchParams to a string
//       });

//       const result = await response.json();
//       if (response.ok) {
//         // Handle successful registration
//         navigate("/verify");
//       } else {
//         // Set the error message from the server response
//         setErrorMessage(result.message);
//       }
//     } catch (error) {
//       console.error("Error during signup:", error);
//       setErrorMessage("An error occurred during signup. Please try again.");
//     }
//   };

//   return (
//     <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
//       <Helmet>
//         <title>Sign up || House of Web3</title>
//       </Helmet>
//       <div className="container mb-24 lg:mb-32">
//         <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
//           Signup
//         </h2>
//         <div className="max-w-md mx-auto space-y-6 ">
//           <div className="grid gap-3">
//             {loginSocials.map((item, index) => (
//               <a
//                 key={index}
//                 href={item.href}
//                 className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
//               >
//                 <img
//                   className="flex-shrink-0"
//                   src={item.icon}
//                   alt={item.name}
//                 />
//                 <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
//                   {item.name}
//                 </h3>
//               </a>
//             ))}
//           </div>
//           {/* OR */}
//           <div className="relative text-center">
//             <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
//               OR
//             </span>
//             <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
//           </div>
//           {/* FORM */}
//           <form className="grid grid-cols-1 gap-6"
//            action="http://localhost:5000/register" method="post"
//            onSubmit={handleSubmit}
//            >
//             <label className="block">
//               <span className="text-neutral-800 dark:text-neutral-200">
//                 Email address
//               </span>
//               <Input
//                 type="email"
//                 placeholder="example@example.com"
//                 className="mt-1"
//                 name="username"
//                 required
//               />
//             </label>
//             <label className="block">
//               <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
//                 Password
//               </span>
//               <Input required type="password" name="password" className="mt-1" />
//             </label>
//             {errorMessage && (
//               <div className="text-red-500 text-center">{errorMessage}</div>
//             )}
//             <ButtonPrimary type="submit">Continue</ButtonPrimary>
//           </form>

//           {/* ==== */}
//           <span className="block text-center text-neutral-700 dark:text-neutral-300">
//             Already have an account? {` `}
//             <Link to="/login" className="text-green-600">
//               Sign in
//             </Link>
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PageSignUp;

import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { UserGlobalContext } from "user";

export interface PageSignUpProps {
  className?: string;
}

// GraphQL mutation
const REGISTER_MUTATION = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      success
      message
      token
    }
  }
`;

const loginSocials = [
  {
    name: "Continue with Google",
    href: "http://localhost:5000/auth/google",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { LoggedIn } = UserGlobalContext();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const [register] = useMutation(REGISTER_MUTATION);

  useEffect(() => {
    if (LoggedIn) {
      navigate("/account");
    }
  }, [LoggedIn, navigate]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    const variables = {
      input: {
        username: formData.get("username") as string,
        email: formData.get("email") as string,
        password: formData.get("password") as string,
      },
    };

    try {
      const { data } = await register({ variables });

      if (data.register.success) {
        navigate("/verify");
      } else {
        setErrorMessage(data.register.message);
      }
    } catch (error) {
      console.error("Error during signup:", error);
      setErrorMessage("An error occurred during signup. Please try again.");
    }
  };

  return (
    <div className={`nc-PageSignUp ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || House of Web3</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                name="email"
                required
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Username
              </span>
              <Input
                type="text"
                placeholder="Username"
                name="username"
                required
              />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input required type="password" name="password" />
            </label>
            {errorMessage && (
              <div className="text-red-500 text-center">{errorMessage}</div>
            )}
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account?{" "}
            <Link to="/login" className="text-green-600">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
