import React, { FC, Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Textarea from "shared/Textarea/Textarea";
import Heading from "shared/Heading/Heading";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";
import Input from "shared/Input/Input";
import { UserGlobalContext } from "user";
import Modal from "./PageUpdateTokens/Modal";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import "./style.css";
import { gql, useLazyQuery } from "@apollo/client";
export interface AuthorPageProps {
  className?: string;
}
const SEARCH_DOMAIN = gql`
  query SearchDomain($name: String!) {
    searchDomain(name: $name) {
      available
      cost
      message
    }
  }
`;

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const [subject, setSubject] = useState("");
  const [email1, setEmail1] = useState("");
  const [content, setContent] = useState("");
  const [alertText, setAlertText] = useState("");
  function recorded() {
    axios
      .post(
        "http://localhost:5000/contact",
        {
          email: email1,
          subject: subject,
          content_body: content,
        },
        {
          timeout: 5000,
        }
      )
      .then(function (response) {
        console.log(response);

        if (response.data == "success") {
          setAlertText(
            "We have received your message, will get back to you soon!!"
          );
          setShowAlert(true);
        }
      });
    setShowAlert(true);
  }

  const [showAlert, setShowAlert] = React.useState(false);
  const Alert = (text: any) => {
    return (
      <>
        {showAlert ? (
          <div
            className={
              "text-white px-6 py-8 border-0 rounded relative mb-4 bg-emerald-500"
            }
          >
            <span className="text-xl inline-block mr-5 align-middle">
              <i className="fas fa-bell" />
            </span>
            <span className="inline-block align-middle mr-8">
              {alertText || text}
            </span>
            <button
              className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
              onClick={() => setShowAlert(false)}
            >
              <span>×</span>
            </button>
          </div>
        ) : null}
      </>
    );
  };

  const [name, changeName] = useState("Your Name");
  const [email, changeEmail] = useState("Your email");
  const [bio, changeBio] = useState("Message");
  const [website, changeWebsite] = useState("Your website");
  const [occupation, changeOccupation] = useState("ST");
  const [twitter, changeTwitter] = useState("Twitter Account");
  const [facebook, changeFacebook] = useState("Facebook Account ");
  const [telegram, changeTelegram] = useState("Telegram Account");
  const { cartItems, changeCartItems, array, changeArray, LoggedIn } =
    UserGlobalContext();
  let [categories] = useState([
    "Collectibles",
    "Created",
    "Liked",
    "Following",
    "Followers",
  ]);
  let { state } = useLocation();
  let navigate = useNavigate();
  let searched = "";
  if (state != null) {
    searched = state.domain.toLowerCase();
  }

  const [input, changeInput] = useState(searched);
  const [domains, changeDomains] = useState([
    searched.split(".")[0] + ".upu",
    searched.split(".")[0] + ".bahrain",
    searched.split(".")[0] + ".united",
    searched.split(".")[0] + ".verse",
    searched.split(".")[0] + ".bhd",
    searched.split(".")[0] + ".defi",
  ]);
  const [domainsData1, changeDomainsData1] = useState({
    cost: 0,
    type: "Available",
  });
  const [domainsData2, changeDomainsData2] = useState({
    cost: 10,
    type: "Available",
  });
  const [domainsData3, changeDomainsData3] = useState({
    cost: 0,
    type: "Available",
  });
  const [domainsData4, changeDomainsData4] = useState({
    cost: 0,
    type: "Available",
  });
  const [domainsData5, changeDomainsData5] = useState({
    cost: 0,
    type: "Available",
  });
  const [domainsData6, changeDomainsData6] = useState({
    cost: 0,
    type: "Available",
  });
  const [buttonDisplay, changeDisplay] = useState("block");

  function HandleClick(event: any) {
    navigate("/order-summary", { state: { domain: event.target.value } });
  }
  const [text1, setText1] = useState("Add to Cart");
  const [text2, setText2] = useState("Add to Cart");
  const [style1, setStyle1] = useState(
    "mr-3 my-2 bg-[#0ac74c] hover:opacity-[0.9] text-[#000] text-white font-bold py-2 px-4 rounded"
  );

  useEffect(() => {
    if (domainsData1.type == "Enterprise") {
      setText1("Contact Us");
    }
  }, [domainsData1]);
  const [isOpen, setIsOpen] = useState(false);
  const [cartFlag, setCartFlag] = useState(false);

  function handleOpenModal() {
    setIsOpen(true);
  }
  const handleCloseModal = () => setIsOpen(false);

  useEffect(() => {
    var str = document.cookie.split(";");
    const result: any = {};
    for (let i in str) {
      const cur = str[i].split("=");
      result[cur[0]] = cur[1];
    }
    console.log("cartItem: " + cartItems);
    console.log("domainsss", domains);
    console.log("arrar", array);
    if (cartItems !== 0) {
      // if (array.includes() === true) {
      //   setCartFlag(true)
      // }
    }
  }, [cartFlag]);
  useEffect(() => {
    // Check if the current domain is already in the cart when the component loads
    if (array.includes(input)) {
      setText2("Go to Cart");
    } else {
      setText2("Add to Cart");
    }
  }, [array, domains]);

  function handleClickCart(event: any) {
    const selectedDomain = event.target.value; // Get the selected domain

    if (LoggedIn) {
      if (cartItems !== 0) {
        if (array.includes(selectedDomain)) {
          setCartFlag(true);
          alert("This domain is already added to cart");
        } else {
          setCartFlag(false);
          const updatedArray = [...array, selectedDomain];
          changeCartItems(updatedArray.length);
          changeArray(updatedArray);
          document.cookie = "cartItem=" + JSON.stringify(updatedArray); // Store the cart in cookies
          setText2("Go to Cart"); // Change button text to "Go to Cart"
        }
      } else {
        changeCartItems(1);
        changeArray([selectedDomain]);
        document.cookie = "cartItem=" + JSON.stringify([selectedDomain]);
        setText2("Go to Cart"); // Change button text to "Go to Cart"
      }
    } else {
      // <Alert text="Please Login First" />;
      alert("Please Login First");
    }
  }

  // Function to handle navigation to the cart page
  function handleGoToCart() {
    navigate("/cart");
  }

  function handleRemoveFromCart(domain: string) {
    const updatedArray = array.filter((item) => item !== domain); // Remove the selected domain from the cart
    changeCartItems(updatedArray.length);
    changeArray(updatedArray);
    document.cookie = "cartItem=" + JSON.stringify(updatedArray); // Update the cart in cookies
    setText2("Add to Cart"); // Change button text back to "Add to Cart"
  }

  function HandleAlert() {
    setAlertText("This domain is not available");
    setCartFlag(true);
    setShowAlert(true);
  }

  function HandleAlert2() {
    alert("Please contact us for purchasing this domain");
  }

  function HandleChange(event: any) {
    changeInput(event.target.value.toLowerCase());
  }
  function HandleEnter(event: any) {
    if (event.key === "Enter") {
      changeDomains([
        input.split(".")[0].split(" ").join("") + ".upu",
        input.split(".")[0].split(" ").join("") + ".bahrain",
        input.split(".")[0].split(" ").join("") + ".united",
        input.split(".")[0].split(" ").join("") + ".verse",
        input.split(".")[0].split(" ").join("") + ".bhd",
        input.split(".")[0].split(" ").join("") + ".defi",
      ]);
    }
  }

  const [searchResult, setSearchResult] = useState({
    available: false,
    cost: 0,
    message: "",
  });
  const [searchDomain, { loading, error, data }] = useLazyQuery(SEARCH_DOMAIN, {
    onCompleted: (data) => {
      // Update search result when query completes
      console.log("Search Domain Response:", data);
      setSearchResult(data.searchDomain);
    },
  });

  useEffect(()=> {
    if (!input.includes(".")) {
      changeInput("." + input);
    }
  })

  const HandleSubmit = (e: any) => {
    e.preventDefault(); // Prevent default form submission

    // Regular expression to match domain format (alphabets, numbers, and one dot)
    const domainRegex = /^\.?[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)?$/;

    // Check if the input is valid
    if (!input || !domainRegex.test(input)) {
      alert("Invalid domain or TLD format");
      return; // Stop the submission if invalid
    }
    if (!input.includes(".")) {
      changeInput("." + input);
    }

    // Execute the searchDomain query
    console.log("Executing search",searchDomain);
    searchDomain({ variables: { name: input } });
  };
  useEffect(() => {
    searchDomain({ variables: { name: input } });
  }, [input]);
  function HandleSearchClick() {
    changeDomains([
      input.split(".")[0].split(" ").join("") + ".upu",
      input.split(".")[0].split(" ").join("") + ".bahrain",
      input.split(".")[0].split(" ").join("") + ".united",
      input.split(".")[0].split(" ").join("") + ".verse",
      input.split(".")[0].split(" ").join("") + ".bhd",
      input.split(".")[0].split(" ").join("") + ".defi",
    ]);
  }
  // HandleEnter({key:"Enter"});
  useEffect(() => {
    Axios.get("http://localhost:5000/search/" + domains[0], {
      timeout: 5000,
    })
      .then((response) => {
        changeDomainsData1(response.data);
      })
      .catch((err) => console.log(err));
    Axios.get("http://localhost:5000/search/" + domains[1])
      .then((response) => {
        changeDomainsData2(response.data);
      })
      .catch((err) => console.log(err));
    Axios.get("http://localhost:5000/search/" + domains[2])
      .then((response) => {
        changeDomainsData3(response.data);
      })
      .catch((err) => console.log(err));
    Axios.get("http://localhost:5000/search/" + domains[3])
      .then((response) => {
        changeDomainsData4(response.data);
      })
      .catch((err) => console.log(err));
    Axios.get("http://localhost:5000/search/" + domains[4])
      .then((response) => {
        changeDomainsData5(response.data);
      })
      .catch((err) => console.log(err));
    // Axios.get("http://localhost:5000/search/" + domains[5])
    //   .then((response) => {
    //     changeDomainsData6(response.data);
    // })
    // .catch((err) => console.log(err));
  }, [domains]);

  const [text, setText] = useState("Buy Now");

  const [selectedButton, setSelectedButton] = useState<string | null>("tld");

  const handleRadioClick = (buttonValue: string) => {
    // Allow unselecting by clicking twice
    if (selectedButton === buttonValue) {
      setSelectedButton(null); // Unselect if clicked twice
    } else {
      setSelectedButton(buttonValue); // Select the clicked button
    }
  };

  const [searchedDomain, setSearchedDomain] = useState<string | null>("");
  const [response, setResponse] = useState<string | null>("");

  useEffect(() => {
    const checkAvailability = async () => {
      // setLoading(true);
      try {
        const response = await Axios.post(
          "http://localhost:5000/checkavailability",
          { domain: searchedDomain }
        );
        // Extracting response data
        // setAvailable(response.data.available);
        // setMessage(response.data.message);
        setResponse(response.data);
        console.log("response: " + response);
      } catch (error) {
        console.error("Error checking domain availability", error);
        // setMessage("An error occurred while checking availability.");
      } finally {
        // setLoading(false);
      }
    };
    checkAvailability();
  }, [searchedDomain]);

  return (
    <div className={`nc-AuthorPage  ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Search || House of Web3</title>
      </Helmet>

      <div className="fixed top-0 inset-x-0 z-50">
        <Alert />
      </div>

      <Heading
        className="mt-20 mb-10 text-neutral-900 dark:text-neutral-50"
        fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
        isCenter
        desc=""
      >
        Search Result
      </Heading>

      <div className="container w-full md:w-3/4">
        <div className="relative">
          <form onSubmit={HandleSubmit}>
            <Input
              value={input}
              onChange={HandleChange}
              onKeyDown={HandleEnter}
              type="search"
              placeholder="Search your domain"
              className="pr-16"
              sizeClass="h-[42px] pl-4 py-6"
            />
            <span className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500">
              <button type="submit">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </span>
          </form>
        </div>

        {/* Toaster will be here */}
        {/* <Toaster position="top-right" /> */}

        {/* Radio buttons below the input */}
        <div className="flex justify-start space-x-4 mt-4">
          <label className="inline-flex items-center">
            <span className="mr-3 bg-transparent text-black dark:text-white">
              TLD First
            </span>
            <div
              className={`w-4 h-4 border border-gray-300 rounded-sm flex items-center justify-center cursor-pointer ${
                selectedButton === "tld" ? "bg-green-500" : "bg-transparent"
              }`}
              onClick={() => handleRadioClick("tld")}
            >
              {selectedButton === "tld" && (
                <svg
                  className="w-4 h-4 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              )}
            </div>
          </label>

          <label className="inline-flex items-center">
            <span className="mr-3 text-black dark:text-white bg-transparent">
              Domain First
            </span>
            <div
              className={`w-4 h-4 border border-gray-300 rounded-sm flex items-center justify-center cursor-pointer ${
                selectedButton === "domain" ? "bg-green-500" : "bg-transparent"
              }`}
              onClick={() => handleRadioClick("domain")}
            >
              {selectedButton === "domain" && (
                <svg
                  className="w-4 h-4 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              )}
            </div>
          </label>
        </div>
      </div>

      <div className="container w-full md:w-3/4 mt-12">
        <div className="relative flex flex-col gap-1 mb-3">
          <div className="flex flex-col w-full">
            <h2 className="text-xl font-bold mb-2">Results</h2>
            <div className="bg-[#0ac74c] border border-none rounded-2xl">
              <div className="flex justify-between mx-4 my-1 font-bold text-white">
                <span className="">
                  {/* {searchResult.available ? "Available" : "Not Available"} */}
                  Available
                </span>
                <span className="">
                  {input.startsWith(".") || !input.includes(".")
                    ? "TLD"
                    : "Domain + TLD"}
                </span>
              </div>
              <div className="flex flex-col items-start md:flex-row md:items-center md:justify-between bg-white border md:p-4 p-1 rounded-2xl m-1">
                <div className="md:flex flex-col  md:mb-0">
                  <span className="text-[#0ac74c] font-bold mr-3">
                    {/* {!input.includes(".") ? "." : ""} */}
                    {input}
                  </span>
                  <span className="text-gray-600 ">AVAILABLE</span>
                </div>
                <span className="text-black  text-lg font-bold mb-2 md:mb-0">
                  {searchResult.available && <p>${searchResult.cost}</p>}
                </span>
                <div className="flex items-center">
                  {text2 === "Go to Cart" && (
                    <button
                      onClick={() => handleRemoveFromCart(input)} // Remove from cart when clicked
                      className="text-white px-2 py-1 rounded-full bg-red-500 hover:bg-red-600 mr-2"
                    >
                      ✕
                    </button>
                  )}

                  <button
                    value={input}
                    onClick={
                      text2 === "Add to Cart" ? handleClickCart : handleGoToCart
                    } // Navigate to cart if text is "Go to Cart"
                    className="text-white px-8 py-2 rounded-xl bg-[#0ac74c] hover:opacity-[0.9] w-full md:w-auto"
                  >
                    {text2}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <h2 className="text-xl font-bold mb-4">Suggested TLDs </h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="p-6 bg-gray-100 rounded-xl shadow-sm">
              <h3 className="text-[#0ac74c] font-bold mb-2">
                crypto.upu
              </h3>
              <span className="text-[#0ac74c] text-xs uppercase font-semibold bg-purple-100 p-1 rounded">
                Premium
              </span>
              <span className="block text-black font-bold mt-2">$12</span>
              <button
                value="bhavesh.metaverse"
                onClick={handleClickCart}
                className="mt-4 text-white  px-4 py-2 rounded bg-[#0ac74c] hover:opacity-[0.9]"
              >
                Add to Cart
              </button>
            </div>
            <div className="p-6 bg-gray-100 rounded shadow-sm">
              <h3 className="text-[#0ac74c] font-bold mb-2">united.protocol</h3>
              <span className="text-[#0ac74c] text-xs uppercase font-semibold bg-purple-100 p-1 rounded">
                Premium
              </span>
              <span className="block text-black font-bold mt-2">$12</span>
              <button
                value="yash.yash"
                onClick={handleClickCart}
                className="mt-4 text-white  px-4 py-2 rounded bg-[#0ac74c] hover:opacity-[0.9]"
              >
                Add to Cart
              </button>
            </div>
            <div className="p-6 bg-gray-100 rounded shadow-sm">
              <h3 className="text-[#0ac74c] font-bold mb-2">hey.web3</h3>
              <span className="text-[#0ac74c] text-xs uppercase font-semibold bg-purple-100 p-1 rounded">
                Premium
              </span>
              <span className="block text-black font-bold mt-2">$12</span>
              <button
                value="hey.web3"
                onClick={handleClickCart}
                className="mt-4 text-white  px-4 py-2 rounded bg-[#0ac74c] hover:opacity-[0.9]"
              >
                Add to Cart
              </button>
            </div>
            <div className="p-6 bg-gray-100 rounded shadow-sm">
              <h3 className="text-[#0ac74c] font-bold mb-2">meta.verse</h3>
              <span className="text-[#0ac74c] text-xs uppercase font-semibold bg-purple-100 p-1 rounded">
                Premium
              </span>
              <span className="block text-black font-bold mt-2">$12</span>
              <button
                value="hello.x"
                onClick={handleClickCart}
                className="mt-4 text-white  px-4 py-2 rounded bg-[#0ac74c] hover:opacity-[0.9]"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* HEADER */}
      {/* <div className="w-full">
        <div className="relative w-full h-40 md:h-60 2xl:h-72">
          <NcImage
            containerClassName="absolute inset-0"
            src={authorBanner}
            className="object-cover w-full h-full"
          />
        </div>
      </div> */}
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <label className="block">
          <Label>Email</Label>

          <Input
            onChange={(event: any) => setEmail1(event.target.value)}
            name="email"
            placeholder="example@gmail.com"
            type="text"
            className="mt-1"
            value={email}
          />
        </label>
        <label className="block">
          <Label>Occupation</Label>
          <select
            value={subject}
            onChange={(event: any) => setSubject(event.target.value)}
            name="subject"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ddark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
          >
            <option value="Student">Student</option>
            <option value="Company">Company</option>
            <option value="Employee">Employee</option>
            <option value="Engineer">Engineer</option>
            <option value="Other">Other</option>
          </select>
        </label>
        <label className="block">
          <Label>Message</Label>

          <Textarea
            name="content"
            value={content}
            onChange={(event: any) => setContent(event.target.value)}
            className="mt-1"
            rows={6}
          />
        </label>

        <div>
          <ButtonPrimary onClick={recorded}>Submit</ButtonPrimary>
        </div>
      </Modal>

      {selectedButton === "tld" || selectedButton === null ? (
        <div className="my-20 flex flex-col items-center  justify-center container">
          <div className="flex flex-col items-center  justify-center">
            {/* <hr className="mx-auto w-5/6 "></hr> */}
            <h1 className="mx-auto w-5/6 text-xl font-bold mb-4">
              Suggested TLDs
            </h1>
            <span className="w-5/6 mx-auto text-base md:text-md text-neutral-500 dark:text-neutral-400">
              Choose the top-ranking TLDs suggested by UP algorithms. Expand to
              see more.
            </span>
            <div className="flex  rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container">
              <h5 className="text-xl  text-justify text-gray-900 mt-2 dark:text-white">
                {/* {domains[0]} */}
                .upu
              </h5>
              <span className="">
                <div
                  className={
                    domainsData1.type === "Not Available"
                      ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                  }
                >
                  <p
                    className={
                      domainsData1.type === "Not Available"
                        ? "text-sm leading-5 text-red-700"
                        : "text-sm leading-5 text-[#0ac74c]"
                    }
                  >
                    {/* {domainsData1.type} */}
                    Available
                  </p>
                </div>
              </span>
              <span className="text-xl mt-3  text-gray-900 dark:text-white">
                {/* ${domainsData1.cost} */}
                $12
              </span>
              <div className="flex justify-between mb-2">
                <div className=" mt-4 ">
                  <button
                    // value={domains[0]}
                    value=".hello"
                    className={
                      cartFlag
                        ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                        : `${style1}`
                    }
                    onClick={
                      domainsData1.type === "Not Available"
                        ? HandleAlert
                        : domainsData1.type === "Enterprise"
                        ? handleOpenModal
                        : handleClickCart
                    }
                  >
                    {text1}
                  </button>
                </div>
              </div>
            </div>
            <hr className="mx-auto w-5/6 "></hr>
            {/* 2 */}
            <div className="flex  rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container">
              <h5 className="text-xl  text-justify text-gray-900 mt-2 dark:text-white">
                {/* {domains[0]} */}
                .asap
              </h5>
              <span className="">
                <div
                  className={
                    domainsData1.type === "Not Available"
                      ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                  }
                >
                  <p
                    className={
                      domainsData1.type === "Not Available"
                        ? "text-sm leading-5 text-red-700"
                        : "text-sm leading-5 text-[#0ac74c]"
                    }
                  >
                    {/* {domainsData1.type} */}
                    Available
                  </p>
                </div>
              </span>
              <span className="text-xl mt-3  text-gray-900 dark:text-white">
                {/* ${domainsData1.cost} */}
                $12
              </span>
              <div className="flex justify-between mb-2">
                <div className=" mt-4 ">
                  <button
                    // value={domains[0]}
                    value=".mh"
                    className={
                      cartFlag
                        ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                        : `${style1}`
                    }
                    onClick={
                      domainsData1.type === "Not Available"
                        ? HandleAlert
                        : domainsData1.type === "Enterprise"
                        ? handleOpenModal
                        : handleClickCart
                    }
                  >
                    {text1}
                  </button>
                </div>
              </div>
            </div>
            <hr className="mx-auto w-5/6 "></hr>
            {/* 3 */}
            <div className="flex  rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container">
              <h5 className="text-xl  text-justify text-gray-900 mt-2 dark:text-white">
                {/* {domains[0]} */}
                .afk
              </h5>
              <span className="">
                <div
                  className={
                    domainsData1.type === "Not Available"
                      ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                  }
                >
                  <p
                    className={
                      domainsData1.type === "Not Available"
                        ? "text-sm leading-5 text-red-700"
                        : "text-sm leading-5 text-[#0ac74c]"
                    }
                  >
                    {/* {domainsData1.type} */}
                    Available
                  </p>
                </div>
              </span>
              <span className="text-xl mt-3  text-gray-900 dark:text-white">
                {/* ${domainsData1.cost} */}
                $12
              </span>
              <div className="flex justify-between mb-2">
                <div className=" mt-4 ">
                  <button
                    // value={domains[0]}
                    value=".sent"
                    className={
                      cartFlag
                        ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                        : `${style1}`
                    }
                    onClick={
                      domainsData1.type === "Not Available"
                        ? HandleAlert
                        : domainsData1.type === "Enterprise"
                        ? handleOpenModal
                        : handleClickCart
                    }
                  >
                    {text1}
                  </button>
                </div>
              </div>
            </div>
            <hr className="mx-auto w-5/6 "></hr>
            {/* 4 */}
            <div className="flex  rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container">
              <h5 className="text-xl  text-justify text-gray-900 mt-2 dark:text-white">
                {/* {domains[0]} */}
                .verse
              </h5>
              <span className="">
                <div
                  className={
                    domainsData1.type === "Not Available"
                      ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                  }
                >
                  <p
                    className={
                      domainsData1.type === "Not Available"
                        ? "text-sm leading-5 text-red-700"
                        : "text-sm leading-5 text-[#0ac74c]"
                    }
                  >
                    {/* {domainsData1.type} */}
                    Available
                  </p>
                </div>
              </span>
              <span className="text-xl mt-3  text-gray-900 dark:text-white">
                {/* ${domainsData1.cost} */}
                $12
              </span>
              <div className="flex justify-between mb-2">
                <div className=" mt-4 ">
                  <button
                    // value={domains[0]}
                    value=".george"
                    className={
                      cartFlag
                        ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                        : `${style1}`
                    }
                    onClick={
                      domainsData1.type === "Not Available"
                        ? HandleAlert
                        : domainsData1.type === "Enterprise"
                        ? handleOpenModal
                        : handleClickCart
                    }
                  >
                    {text1}
                  </button>
                </div>
              </div>
            </div>
            <hr className="mx-auto w-5/6 "></hr>
            {/* 5 */}
            <div className="flex rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container">
              <h5 className="text-xl text-justify text-gray-900 mt-2 dark:text-white">
                {/* {domains[0]} */}
                .greenfield
              </h5>
              <span className="">
                <div
                  className={
                    domainsData1.type === "Not Available"
                      ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                  }
                >
                  <p
                    className={
                      domainsData1.type === "Not Available"
                        ? "text-sm leading-5 text-red-700"
                        : "text-sm leading-5 text-[#0ac74c]"
                    }
                  >
                    {/* {domainsData1.type} */}
                    Available
                  </p>
                </div>
              </span>
              <span className="text-xl mt-3  text-gray-900 dark:text-white">
                {/* ${domainsData1.cost} */}
                $12
              </span>
              <div className="flex justify-between mb-2">
                <div className=" mt-4 ">
                  <button
                    // value={domains[0]}
                    value=".trace"
                    className={
                      cartFlag
                        ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                        : `${style1}`
                    }
                    onClick={
                      domainsData1.type === "Not Available"
                        ? HandleAlert
                        : domainsData1.type === "Enterprise"
                        ? handleOpenModal
                        : handleClickCart
                    }
                  >
                    {text1}
                  </button>
                </div>
              </div>
            </div>
            <hr className="mx-auto w-5/6 "></hr>
          </div>
        </div>
      ) : (
        <div className="my-20 flex flex-col items-center  justify-center container">
          <div className="flex flex-col items-center  justify-center">
            {/* <hr className="mx-auto w-5/6 "></hr> */}
            <h1 className="mx-auto w-5/6 text-xl font-bold mb-4">
              Suggested Domains
            </h1>
            <span className="w-5/6 mx-auto text-base md:text-md text-neutral-500 dark:text-neutral-400">
              Choose the top-ranking TLDs suggested by UP algorithms. Expand to
              see more.
            </span>
            <div className="flex  rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container">
              <h5 className="text-xl  text-justify text-gray-900 mt-2 dark:text-white">
                {/* {domains[0]} */}
                bnb.greenfield
              </h5>
              <span className="">
                <div
                  className={
                    domainsData1.type === "Not Available"
                      ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                  }
                >
                  <p
                    className={
                      domainsData1.type === "Not Available"
                        ? "text-sm leading-5 text-red-700"
                        : "text-sm leading-5 text-[#0ac74c]"
                    }
                  >
                    {/* {domainsData1.type} */}
                    Available
                  </p>
                </div>
              </span>
              <span className="text-xl mt-3  text-gray-900 dark:text-white">
                {/* ${domainsData1.cost} */}
                $12
              </span>
              <div className="flex justify-between mb-2">
                <div className=" mt-4 ">
                  <button
                    // value={domains[0]}
                    value="hello.hello"
                    className={
                      cartFlag
                        ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                        : `${style1}`
                    }
                    onClick={
                      domainsData1.type === "Not Available"
                        ? HandleAlert
                        : domainsData1.type === "Enterprise"
                        ? handleOpenModal
                        : handleClickCart
                    }
                  >
                    {text1}
                  </button>
                </div>
              </div>
            </div>
            <hr className="mx-auto w-5/6 "></hr>
            {/* 2 */}
            <div className="flex  rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container">
              <h5 className="text-xl  text-justify text-gray-900 mt-2 dark:text-white">
                {/* {domains[0]} */}
                999.afk
              </h5>
              <span className="">
                <div
                  className={
                    domainsData1.type === "Not Available"
                      ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                  }
                >
                  <p
                    className={
                      domainsData1.type === "Not Available"
                        ? "text-sm leading-5 text-red-700"
                        : "text-sm leading-5 text-[#0ac74c]"
                    }
                  >
                    {/* {domainsData1.type} */}
                    Available
                  </p>
                </div>
              </span>
              <span className="text-xl mt-3  text-gray-900 dark:text-white">
                {/* ${domainsData1.cost} */}
                $12
              </span>
              <div className="flex justify-between mb-2">
                <div className=" mt-4 ">
                  <button
                    // value={domains[0]}
                    value="mh.mh"
                    className={
                      cartFlag
                        ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                        : `${style1}`
                    }
                    onClick={
                      domainsData1.type === "Not Available"
                        ? HandleAlert
                        : domainsData1.type === "Enterprise"
                        ? handleOpenModal
                        : handleClickCart
                    }
                  >
                    {text1}
                  </button>
                </div>
              </div>
            </div>
            <hr className="mx-auto w-5/6 "></hr>
            {/* 3 */}
            <div className="flex  rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container">
              <h5 className="text-xl  text-justify text-gray-900 mt-2 dark:text-white">
                {/* {domains[0]} */}
                rush.asap
              </h5>
              <span className="">
                <div
                  className={
                    domainsData1.type === "Not Available"
                      ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                  }
                >
                  <p
                    className={
                      domainsData1.type === "Not Available"
                        ? "text-sm leading-5 text-red-700"
                        : "text-sm leading-5 text-[#0ac74c]"
                    }
                  >
                    {/* {domainsData1.type} */}
                    Available
                  </p>
                </div>
              </span>
              <span className="text-xl mt-3  text-gray-900 dark:text-white">
                {/* ${domainsData1.cost} */}
                $12
              </span>
              <div className="flex justify-between mb-2">
                <div className=" mt-4 ">
                  <button
                    // value={domains[0]}
                    value="sent.sent"
                    className={
                      cartFlag
                        ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                        : `${style1}`
                    }
                    onClick={
                      domainsData1.type === "Not Available"
                        ? HandleAlert
                        : domainsData1.type === "Enterprise"
                        ? handleOpenModal
                        : handleClickCart
                    }
                  >
                    {text1}
                  </button>
                </div>
              </div>
            </div>
            <hr className="mx-auto w-5/6 "></hr>
            {/* 4 */}
            <div className="flex  rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container">
              <h5 className="text-xl  text-justify text-gray-900 mt-2 dark:text-white">
                {/* {domains[0]} */}
                token.upu
              </h5>
              <span className="">
                <div
                  className={
                    domainsData1.type === "Not Available"
                      ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                  }
                >
                  <p
                    className={
                      domainsData1.type === "Not Available"
                        ? "text-sm leading-5 text-red-700"
                        : "text-sm leading-5 text-[#0ac74c]"
                    }
                  >
                    {/* {domainsData1.type} */}
                    Available
                  </p>
                </div>
              </span>
              <span className="text-xl mt-3  text-gray-900 dark:text-white">
                {/* ${domainsData1.cost} */}
                $12
              </span>
              <div className="flex justify-between mb-2">
                <div className=" mt-4 ">
                  <button
                    // value={domains[0]}
                    value="george.george"
                    className={
                      cartFlag
                        ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                        : `${style1}`
                    }
                    onClick={
                      domainsData1.type === "Not Available"
                        ? HandleAlert
                        : domainsData1.type === "Enterprise"
                        ? handleOpenModal
                        : handleClickCart
                    }
                  >
                    {text1}
                  </button>
                </div>
              </div>
            </div>
            <hr className="mx-auto w-5/6 "></hr>
            {/* 5 */}
            <div className="flex rounded-md lg:justify-end items-center lg:my-0 my-5 lg:space-x-32 flex-col lg:flex-row container">
              <h5 className="text-xl text-justify text-gray-900 mt-2 dark:text-white">
                {/* {domains[0]} */}
                ai.verse
              </h5>
              <span className="">
                <div
                  className={
                    domainsData1.type === "Not Available"
                      ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                      : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                  }
                >
                  <p
                    className={
                      domainsData1.type === "Not Available"
                        ? "text-sm leading-5 text-red-700"
                        : "text-sm leading-5 text-[#0ac74c]"
                    }
                  >
                    {/* {domainsData1.type} */}
                    Available
                  </p>
                </div>
              </span>
              <span className="text-xl mt-3  text-gray-900 dark:text-white">
                {/* ${domainsData1.cost} */}
                $12
              </span>
              <div className="flex justify-between mb-2">
                <div className=" mt-4 ">
                  <button
                    // value={domains[0]}
                    value="trace.trace"
                    className={
                      cartFlag
                        ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                        : `${style1}`
                    }
                    onClick={
                      domainsData1.type === "Not Available"
                        ? HandleAlert
                        : domainsData1.type === "Enterprise"
                        ? handleOpenModal
                        : handleClickCart
                    }
                  >
                    {text1}
                  </button>
                </div>
              </div>
            </div>
            <hr className="mx-auto w-5/6 "></hr>
          </div>
        </div>
      )}

      {/* <div className="flex flex-col">
          <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden rounded">
                <table className="min-w-full">
                  <thead className="bg-gray-200 dark:bg-neutral-800 border-b">
                    <tr>
                      <th
                        scope="col"
                        className="text-sm dark:text-neutral-50 font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="text-sm dark:text-neutral-50 font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Domain Name
                      </th>
                      <th
                        scope="col"
                        className="text-sm dark:text-neutral-50 font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="text-sm dark:text-neutral-50 font-medium text-gray-900 px-12 py-4 text-left"
                      >
                        Availability
                      </th>
                      <th
                        scope="col"
                        className="text-sm dark:text-neutral-50 font-medium text-gray-900 px-12 py-4 text-left"
                        style={{ display: buttonDisplay }}
                      ></th>
                    </tr>
                  </thead>

                 
                  <tbody>
                    <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
                      <td className="px-6 py-4 dark:text-neutral-50 whitespace-nowrap text-sm font-medium text-gray-900">
                        {1}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domains[0]}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domainsData1.cost}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <div
                          className={
                            domainsData1.type === "Not Available"
                              ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                              : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                          }
                        >
                          <p
                            className={
                              domainsData1.type === "Not Available"
                                ? "text-sm leading-5 text-red-700"
                                : "text-sm leading-5 text-green-700"
                            }
                          >
                            {domainsData1.type}
                          </p>
                        </div>
                      </td>

                      <td
                        className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                        style={{ display: buttonDisplay }}
                      >
                        <button
                          value={domains[0]}
                          className={style1}
                          onClick={
                            domainsData1.type === "Not Available"
                              ? HandleAlert
                              : domainsData1.type === "Enterprise"
                              ? handleOpenModal
                              : handleClickCart
                          }
                        >
                          {text1}
                        </button>
                        <button
                          value={domains[0]}
                          onClick={
                            domainsData1.type === "Not Available"
                              ? HandleAlert
                              : domainsData1.type === "Enterprise"
                              ? HandleAlert2
                              : HandleClick
                          }
                          className={
                            domainsData1.type === "Not Available" ||
                            domainsData1.type === "Enterprise"
                              ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded disabled:opacity-75 cursor-not-allowed"
                              : "my-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                          }
                        >
                          {text}
                        </button>
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
                      <td className="px-6 py-4 dark:text-neutral-50 whitespace-nowrap text-sm font-medium text-gray-900">
                        {2}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domains[1]}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domainsData2.cost}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <div
                          className={
                            domainsData2.type === "Not Available"
                              ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                              : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                          }
                        >
                          <p
                            className={
                              domainsData2.type === "Not Available"
                                ? "text-sm leading-5 text-red-700"
                                : "text-sm leading-5 text-green-700"
                            }
                          >
                            {domainsData2.type}
                          </p>
                        </div>
                      </td>

                      <td
                        className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                        style={{ display: buttonDisplay }}
                      >
                        <button
                          value={domains[1]}
                          className={style1}
                          onClick={
                            domainsData2.type === "Not Available"
                              ? HandleAlert
                              : domainsData2.type === "Enterprise"
                              ? handleOpenModal
                              : handleClickCart
                          }
                        >
                          {text1}
                        </button>
                        <button
                          value={domains[1]}
                          onClick={
                            domainsData2.type === "Not Available"
                              ? HandleAlert
                              : domainsData2.type === "Enterprise"
                              ? HandleAlert2
                              : HandleClick
                          }
                          className={
                            domainsData2.type === "Not Available" ||
                            domainsData2.type === "Enterprise"
                              ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
                              : "my-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                          }
                        >
                          {text}
                        </button>
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
                      <td className="px-6 py-4 dark:text-neutral-50 whitespace-nowrap text-sm font-medium text-gray-900">
                        {3}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domains[2]}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domainsData3.cost}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <div
                          className={
                            domainsData3.type === "Not Available"
                              ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                              : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                          }
                        >
                          <p
                            className={
                              domainsData3.type === "Not Available"
                                ? "text-sm leading-5 text-red-700"
                                : "text-sm leading-5 text-green-700"
                            }
                          >
                            {domainsData3.type}
                          </p>
                        </div>
                      </td>

                      <td
                        className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                        style={{ display: buttonDisplay }}
                      >
                        <button
                          value={domains[2]}
                          className={style1}
                          onClick={
                            domainsData3.type === "Not Available"
                              ? HandleAlert
                              : domainsData3.type === "Enterprise"
                              ? handleOpenModal
                              : handleClickCart
                          }
                        >
                          {text1}
                        </button>
                        <button
                          value={domains[2]}
                          onClick={
                            domainsData3.type === "Not Available"
                              ? HandleAlert
                              : domainsData3.type === "Enterprise"
                              ? HandleAlert2
                              : HandleClick
                          }
                          className={
                            domainsData3.type === "Not Available" ||
                            domainsData3.type === "Enterprise"
                              ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
                              : "my-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                          }
                        >
                          {text}
                        </button>
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
                      <td className="px-6 py-4 dark:text-neutral-50 whitespace-nowrap text-sm font-medium text-gray-900">
                        {4}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domains[3]}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domainsData4.cost}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <div
                          className={
                            domainsData4.type === "Not Available"
                              ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                              : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                          }
                        >
                          <p
                            className={
                              domainsData4.type === "Not Available"
                                ? "text-sm leading-5 text-red-700"
                                : "text-sm leading-5 text-green-700"
                            }
                          >
                            {domainsData4.type}
                          </p>
                        </div>
                      </td>

                      <td
                        className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                        style={{ display: buttonDisplay }}
                      >
                        <button
                          value={domains[3]}
                          className={style1}
                          onClick={
                            domainsData4.type === "Not Available"
                              ? HandleAlert
                              : domainsData4.type === "Enterprise"
                              ? handleOpenModal
                              : handleClickCart
                          }
                        >
                          {text1}
                        </button>
                        <button
                          value={domains[3]}
                          onClick={
                            domainsData4.type === "Not Available"
                              ? HandleAlert
                              : domainsData4.type === "Enterprise"
                              ? HandleAlert2
                              : HandleClick
                          }
                          className={
                            domainsData4.type === "Not Available" ||
                            domainsData4.type === "Enterprise"
                              ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
                              : "my-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                          }
                        >
                          {text}
                        </button>
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
                      <td className="px-6 py-4 dark:text-neutral-50 whitespace-nowrap text-sm font-medium text-gray-900">
                        {5}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domains[4]}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domainsData5.cost}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <div
                          className={
                            domainsData5.type === "Not Available"
                              ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                              : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                          }
                        >
                          <p
                            className={
                              domainsData5.type === "Not Available"
                                ? "text-sm leading-5 text-red-700"
                                : "text-sm leading-5 text-green-700"
                            }
                          >
                            {domainsData5.type}
                          </p>
                        </div>
                      </td>

                      <td
                        className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                        style={{ display: buttonDisplay }}
                      >
                        <button
                          value={domains[4]}
                          className={style1}
                          onClick={
                            domainsData5.type === "Not Available"
                              ? HandleAlert
                              : domainsData5.type === "Enterprise"
                              ? handleOpenModal
                              : handleClickCart
                          }
                        >
                          {text1}
                        </button>
                        <button
                          value={domains[4]}
                          onClick={
                            domainsData5.type === "Not Available"
                              ? HandleAlert
                              : domainsData5.type === "Enterprise"
                              ? HandleAlert2
                              : HandleClick
                          }
                          className={
                            domainsData5.type === "Not Available" ||
                            domainsData5.type === "Enterprise"
                              ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
                              : "my-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                          }
                        >
                          {text}
                        </button>
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
                      <td className="px-6 py-4 dark:text-neutral-50 whitespace-nowrap text-sm font-medium text-gray-900">
                        {6}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domains[5]}
                      </td>
                      <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {domainsData6.cost}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <div
                          className={
                            domainsData6.type === "Not Available"
                              ? "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-red-100 rounded-full"
                              : "w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"
                          }
                        >
                          <p
                            className={
                              domainsData6.type === "Not Available"
                                ? "text-sm leading-5 text-red-700"
                                : "text-sm leading-5 text-green-700"
                            }
                          >
                            {domainsData6.type}
                          </p>
                        </div>
                      </td>

                      <td
                        className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                        style={{ display: buttonDisplay }}
                      >
                        <button
                          value={domains[5]}
                          className={style1}
                          onClick={
                            domainsData6.type === "Not Available"
                              ? HandleAlert
                              : domainsData6.type === "Enterprise"
                              ? HandleAlert2
                              : handleClickCart
                          }
                        >
                          {text1}
                        </button>
                        <button
                          value={domains[5]}
                          onClick={
                            domainsData6.type === "Not Available"
                              ? HandleAlert
                              : domainsData6.type === "Enterprise"
                              ? handleOpenModal
                              : HandleClick
                          }
                          className={
                            domainsData6.type === "Not Available" ||
                            domainsData6.type === "Enterprise"
                              ? "my-2 bg-gray-300 text-white font-bold py-2 px-4 rounded cursor-not-allowed"
                              : "my-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                          }
                        >
                          {text}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}

      {/* <div className="relative py-16 lg:py-28">
          <BackgroundSection />
          <SectionGridAuthorBox
            sectionStyle="style2"
            data={Array.from("11111111")}
            boxCard="box4"
          />
        </div> */}

      {/* SUBCRIBES */}
      {/* <SectionBecomeAnAuthor />  */}
      {/* </div> */}
    </div>
  );
};

export default AuthorPage;
