import React from "react";
import { NavLink} from "react-router-dom";
import Congratulations from '../images/congrats.gif';

function Congrats() {
  return (
    <div className="mt-15 flex flex-col content-center items-center justify-center rounded overflow-hidden ">
      <img className="w-96" src={Congratulations} alt="congrats image"></img>
        <div className="flex flex-col items-center px-10 py-4 ">
          <div className="font-bold text-4xl my-2">CONGRATULATIONS!</div>
          <p className="py-4 text-base mb-3">
            {/* Thank you for your order */}
            Request sent successfully
    </p>
    <div className="mb-8">
<NavLink
  rel="noopener noreferrer"
  className="bg-[#0ac74c] text-black hover:opacity-[0.9] text-dark  py-2 px-4 rounded-full"
  to="/account">Continue</NavLink></div>
        </div>
</div>
      );
    };
    export default Congrats;
    // https://img.icons8.com/bubbles/200/000000/trophy.png