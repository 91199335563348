import React, { useState } from "react";
import Heading from "components/Heading/Heading";
import { HashScroll } from "react-hash-scroll";

const Faq1 = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [show10, setShow10] = useState(false);
  const [show11, setShow11] = useState(false);

  return (
    <HashScroll hash="#faq">
      <div className=" 2xl:container 2xl:mx-auto md:py-12 lg:px-20 md:px-6 py-9 px-4 ">
        <Heading
          className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
          fontClass="text-3xl md:text-4xl 2xl:text-4xl font-semibold"
          isCenter
          desc=""
        >
          Frequently Asked Questions
        </Heading>
        <div className=" flex md:flex-row flex-col md:space-x-8 md:mt-16 mt-8">
          <div className=" md:w-11/12 lg:w-full w-full md:mt-0 sm:mt-14 mt-10">
            {/* <!-- Shipping Section --> */}
            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
                  How can I view a web3 website built on the United Protocol domain?
                </h3>
                <button
                  aria-label="too"
                  className="cursor-pointer focus:outline-none focus:ring-2 dark:text-neutral-50 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow(!show)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={show ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke=" #6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600 dark:text-neutral-50 mt-4 w-11/12 " +
                  (show ? "block" : "hidden")
                }
              >
                You will need to download DamGo Web3 Browser that supports United Protocol
                domains.
              </p>
            </div>

            <hr className=" my-7 bg-gray-200" />

            {/* <!-- Returns Section --> */}

            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
                  Will I be able to transfer my domain?
                </h3>
                <button
                  aria-label="too"
                  className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow2(!show2)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={show2 ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600  dark:text-neutral-50 mt-4 w-11/12 " +
                  (show2 ? "block" : "hidden")
                }
              >
                Not yet. Very soon.. The domain is currently stored in your United Protocol
                wallet profile.{" "}
              </p>
            </div>

            <hr className=" my-7 bg-gray-200" />

            {/* <!-- Exchange Section --> */}

            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
                  Will I be able to search for and find web3 domain websites on
                  google or other search engines?
                </h3>
                <button
                  aria-label="too"
                  className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow3(!show3)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={show3 ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600 dark:text-neutral-50 mt-4 w-11/12 " +
                  (show3 ? "block" : "hidden")
                }
              >
                No, major search engines don't currently index Web3 domain
                websites since the content is hosted on p2p networks such as
                IPFS.
              </p>
            </div>

            <hr className=" my-7 bg-gray-200" />

            {/* Tracking Section */}

            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
                  Are web3 websites part of ICANN/traditional DNS?
                </h3>
                <button
                  aria-label="too"
                  className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow4(!show4)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={show4 ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600 dark:text-neutral-50 mt-4 w-11/12 " +
                  (show4 ? "blcok" : "hidden")
                }
              >
                {" "}
                No, Web3 domains are alternate roots and are not part of the
                current DNS.
              </p>
            </div>

            <hr className=" my-7 bg-gray-200" />
            {/* <!-- Exchange Section --> */}

            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
                  Will there be some sort of 'whois' record with my personal
                  information associated with my domain?
                </h3>
                <button
                  aria-label="too"
                  className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow5(!show5)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={show5 ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600 dark:text-neutral-50 mt-4 w-11/12 " +
                  (show5 ? "block" : "hidden")
                }
              >
                No, not by default. Sharing ‘whois’ information is opt-in, i.e.
                something that you can choose to do. Otherwise, your identity
                will not be publicly known.
              </p>
            </div>

            <hr className=" my-7 bg-gray-200" />
            {/* <!-- Exchange Section --> */}

            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
            
                  Can someone create the same domain on another blockchain?
                </h3>
                <button
                  aria-label="too"
                  className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow6(!show6)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={show6 ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600 dark:text-neutral-50 mt-4 w-11/12 " +
                  (show6 ? "blcok" : "hidden")
                }
              >
              While nobody can prevent someone from creating the same domain name on another blockchain, we are taking steps to bring interoperability to our blockchain. Our smart contract is currently broadcasted to the top blockchains to record the entries. In addition, we plan to leverage AI and partnerships to bring the highest level of interoperability by connecting our blockchain with all top blockchains.
                
              </p>
            </div>

            <hr className=" my-7 bg-gray-200" />
            {/* <!-- Exchange Section --> */}
            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
                  Are web3 websites part of ICANN/traditional DNS?
                </h3>
                <button
                  aria-label="too"
                  className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow7(!show7)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={show7 ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600 dark:text-neutral-50 mt-4 w-11/12 " +
                  (show7 ? "blcok" : "hidden")
                }
              >
                {" "}
                No, Web3 domains are alternate roots and are not part of the
                current DNS.
              </p>
            </div>

          

            <hr className=" my-7 bg-gray-200" />
            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
                  Will I be able to open any Web3 domain on DamGo Browser?
                </h3>
                <button
                  aria-label="too"
                  className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow8(!show8)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={show8 ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600 dark:text-neutral-50 mt-4 w-11/12 " +
                  (show8? "block" : "hidden")
                }
              >
                Not yet. Very soon. The team is working on partnerships.
              </p>
            </div>

            <hr className=" my-7 bg-gray-200" />
            {/* <!-- Exchange Section --> */}

            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
                  IS DamGo Browser safe to use?
                </h3>
                <button
                  aria-label="too"
                  className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow9(!show9)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/s10g"
                  >
                    <path
                      className={show9 ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600 dark:text-neutral-50 mt-4 w-11/12 " +
                  (show9 ? "block" : "hidden")
                }
              >
                Yes, it is. It is built on the Chromium Web core project, which
                has been used by leading browsers and is used by billions.
              </p>
            </div>

            <hr className=" my-7 bg-gray-200" />
            {/* <!-- Exchange Section --> */}

            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
                  Can I browse Web2 internet on DamGo Browser?
                </h3>
                <button
                  aria-label="too"
                  className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow10(!show10)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={show10 ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600 dark:text-neutral-50 mt-4 w-11/12 " +
                  (show10 ? "block" : "hidden")
                }
              >
                Yes, of course. You can enjoy the similar experience with DamGo
                Browser as your favorite browsers.
              </p>
            </div>

            <hr className=" my-7 bg-gray-200" />

            <div>
              <div className=" flex justify-between items-center cursor-pointer">
                <h3 className=" font-semibold text-xl leading-5 text-gray-800 dark:text-neutral-50">
                Is DamGo Browser free?

                </h3>
                <button
                  aria-label="too"
                  className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                  onClick={() => setShow11(!show11)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className={show11 ? "hidden" : "block"}
                      d="M10 4.1665V15.8332"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.16602 10H15.8327"
                      stroke="#6c757d"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={
                  "font-normal text-base leading-6 text-gray-600 dark:text-neutral-50 mt-4 w-11/12 " +
                  (show11 ? "block" : "hidden")
                }
              >
                Yes, DamGo Browser is completely free to use. Simply download the DamGo Browser for desktop or for Android/iOS (coming soon) to get started.

              </p>
            </div>
          </div>
        </div>
      </div>
    </HashScroll>
  );
};

export default Faq1;
// Can someone create the same domain on another blockchain?
// While nobody can prevent someone from creating the same domain name on another blockchain, we are taking steps to bring interoperability to our blockchain. Our smart contract is currently broadcasted to the top blockchains to record the entries. In addition, we plan to leverage AI and partnerships to bring the highest level of interoperability by connecting our blockchain with all top blockchains.
