import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import PageOrdersummary from "containers/PageOrdersummary/PageOrdersumary";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageUploadItem from "containers/PageUploadItem";
import PageConnectWallet from "containers/PageConnectWallet";
import PageConnectWallet2 from "containers/PageConnectWallet2";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import Congrats from "containers/congrats";
import { UserContext } from "../user";
import PagePrivacypolicy from "containers/PagePrivacypolicy/PagePrivacypolicy";
import PageTerms from "containers/PageTerms/PageTerms";
import PageUpdateTokens from "containers/PageUpdateTokens/PageUpdateTokens";
import PageUpdateProfile from "containers/PageUpdateProfile/PageUpdateProfile";
import PageRoadmap from "containers/PageRoadmap";
import PageExploreDomains from "containers/PageExploreDomains";
import PageAddtoCart from "containers/PageAddtoCart/PageAddtoCart";
import PageDesign from "containers/PageDesign/PageDesign";

import Axios from "axios";
import PageInteroperatibility from "containers/PageInteroperatibility";
import PageDocumentation from "containers/PageDocumentation";
import PageVerify from "containers/PageVerify";
import PageNewpassword from "containers/PageNewpassword";
import PageForgeotpassword from "containers/PageForgeotpassword";
import pageAirdrop from "containers/pageAirdrop";
import LoginSuccess from "containers/LoginSuccess";
import AlreadPurchased from "containers/AlreadyPurchased";
import Unsuccessful from "containers/Unsuccessful";
import { useCustomMutation } from "utils/graphqlUtils";
import { LOGIN_MUTATION } from "utils/graphqlQueries";

export const pages: Page[] = [
  { path: "/", component: PageHome2 },
  { path: "/#", component: PageHome2 },
  { path: "/thenextinternet", component: PageHome },
  { path: "/home3", component: PageHome3 },
  { path: "/home-header-2", component: PageHome },
  { path: "/order-summary", component: PageOrdersummary },
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  { path: "/personal-domain", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/page-upload-item", component: PageUploadItem },
  { path: "/payment", component: PageConnectWallet },
  { path: "/payment2", component: PageConnectWallet2 },
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/congrats", component: Congrats },
  { path: "/subscription", component: PageSubcription },
  { path: "/privacypolicy", component: PagePrivacypolicy },
  { path: "/terms", component: PageTerms },
  { path: "/edit-domain-data", component: PageUpdateTokens },
  { path: "/update-profile", component: PageUpdateProfile },
  { path: "/page404", component: Page404 },
  // { path: "/roadmap", component: PageRoadmap },
  // { path: "/explore-domains", component: PageExploreDomains },
  { path: "/cart", component: PageAddtoCart },
  { path: "/design", component: PageDesign },
  { path: "/airdrop", component: pageAirdrop },
  // { path: "/interoperability", component: PageInteroperatibility },
  { path: "/documentation", component: PageDocumentation },
  { path: "/verify", component: PageVerify },
  { path: "/newpassword", component: PageNewpassword },
  { path: "/forgotpassword", component: PageForgeotpassword },
  { path: "/loginsuccess", component: LoginSuccess },
  { path: "/alreadypurchased", component: AlreadPurchased },
  { path: "/unsuccessful", component: Unsuccessful },
];

const MyRoutes = () => {
  const [LoggedIn, changeLogIn] = useState<boolean>(true);
  const [isTLD, changeIsTLD] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<String>(""); // Set a default empty string
  const [cartItems, changeCartItems] = useState<number>(0);
  const [array, changeArray] = useState<any[]>([]);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  // function checkLog() {
  //   if (LoggedIn === false) {
  //     Axios.get("http://localhost:5000/authenticate", {
  //       withCredentials: true,
  //     })
  //       .then((response) => {
  //         changeLogIn(response.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }
  // checkLog();
  const { executeMutation: login, loading } = useCustomMutation(LOGIN_MUTATION, (data) => {
    changeLogIn(data.login.success);
    console.log("login success? ", data.login.success);
  }, (error) => {
    if (error.graphQLErrors?.length > 0) {
      // setErrorMessage(error.graphQLErrors[0].message);
      console.log("Error: " + error.graphQLErrors[0].message);
    } else if (error.networkError) {
      // setErrorMessage("Network error. Please check your connection.");
      console.log("error",error.networkError);
    } else {
      // setErrorMessage("An error occurred during login. Please try again.");
      console.log("An error occurred during login. Please try again.");
    }
  });
  function check() {
    var str = document.cookie.split(";");
    const result: any = {};
    for (let i in str) {
      const cur = str[i].split("=");
      result[cur[0]] = cur[1];
    }
    if (
      cartItems === 0 &&
      result[" cartItems"] !== "" &&
      result[" cartItem"] != null &&
      JSON.parse(result[" cartItem"] !== "" ? result[" cartItem"] : "")
        .length !== 0
    ) {
      changeArray(
        result[" cartItem"] !== null
          ? JSON.parse(result[" cartItem"] !== "" ? result[" cartItem"] : "")
          : []
      );
      changeCartItems(
        result[" cartItem"] !== null
          ? JSON.parse(result[" cartItem"] !== "" ? result[" cartItem"] : "")
              .length
          : 0
      );
    }
  }
  check();

  return (
    <UserContext.Provider
      value={{
        LoggedIn,
        changeLogIn,
        cartItems,
        changeCartItems,
        array,
        changeArray,
        setDarkMode,
        darkMode,
        isTLD,
        changeIsTLD,
        userProfile,
        setUserProfile
        
      }}
    >
      <BrowserRouter>
        {/* basename={process.env.NODE_ENV === "production" ? "" : ""}*/}
        <ScrollToTop />
        <SiteHeader />
        <Routes>
          {pages.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
          <Route element={<Page404 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default MyRoutes;
