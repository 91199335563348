import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList1/SocialsList1";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import axios from 'axios';
export interface PageContactProps {
  className?: string;
}

const info = [
 //{
  //  title: "🗺 ADDRESS",
//    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
//  },
  {
    title: "💌 EMAIL",
    desc: "team@unitedprotocol.org",
  },
  {
    title: "☎ PHONE",
    desc: "+91 9810702369",
  },
];


const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  const [subject, setSubject]= useState('');
  const [email, setEmail]= useState('');
  const [content, setContent]= useState('');

  function recorded(){
    axios.post('http://localhost:5000/contact', {'email':email, 'subject':subject, 'content_body':content  })
   .then(function(response){
      console.log(response);

      if(response.data=="success"){
        setVisible('block');
      }
    })
    setVisible('block')
  }

  const Alert = () => {
    const [showAlert, setShowAlert] = React.useState(true);
    return (
      <>
        {showAlert ? (
          <div
            className={
              "text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500"
            }
          >
            <span className="text-xl inline-block mr-5 align-middle">
              <i className="fas fa-bell" />
            </span>
            <span className="inline-block align-middle mr-8">
             Feedback Sumbmitted<b className="capitalize">Successfully!!</b> 
              
            </span>
            <button
              className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
              onClick={() => setShowAlert(false)}
            >
              <span>×</span>
            </button>
          </div>
        ) : null}
      </>
    );
  };

  const [visible,setVisible]= useState('none');
  
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || House of Web3</title>
      </Helmet>
      <div style={{display:visible}} className="fixed top-0 inset-x-0 z-50">

      <Alert  />
      </div>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
             
                <label className="block">
                  <Label>Email</Label>

                  <Input onChange={(event:any)=>setEmail(event.target.value)}
                  name="email"
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={email}
                  />
                </label>
                <label className="block">
                  <Label>Subject</Label>
                  <select name="subject" value={subject} onChange={(event:any)=>setSubject(event.target.value)} className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="Developer" >Developer</option>
                    <option value="Validator">Validator</option>
                    <option value="Query">Query</option>
                    <option value="Press">Press</option>
                    <option value="Investor">Investor</option>
                    <option value="Feedback">Feedback</option>
                    <option value="Other">Other</option>
                  </select>
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea name="content" value={content} onChange={(event:any)=> setContent(event.target.value)} className="mt-1" rows={6} />
                </label>
                <label className="block">
                <input required className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault"></input>
                  <Label>Agree to the terms of the Service | Privacy Policy</Label>
                </label>
                <div>
                  <ButtonPrimary onClick={recorded} >Submit</ButtonPrimary>
                </div>
  
               
       
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}
        {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}
      </div>
    </div>
  );
};

export default PageContact;
