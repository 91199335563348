import { createContext, useContext } from "react"
export type UserContent = {
  LoggedIn: boolean
  isTLD: boolean
  cartItems: number
  array : any[]
  darkMode: boolean
  userProfile: String
  setUserProfile: (parameter: String) => void
  changeLogIn:(parameter: boolean) => void
  changeIsTLD:(parameter: boolean) => void
  changeCartItems:(parameter: number) => void
  changeArray:(parameter: any[]) => void
  setDarkMode: (parameter:boolean) => void
}
export const UserContext = createContext<UserContent>({
LoggedIn: false,
isTLD: false,
cartItems: 0,
array:[],
darkMode: true,
userProfile: "",
setUserProfile: () => {},
changeLogIn: () => {},
changeIsTLD: () => {},
changeCartItems: () => {},
changeArray:()=>{},
setDarkMode: ()=>{}
})
export const UserGlobalContext = () => useContext(UserContext)
