// // @ts-ignore: Object is possibly 'null'.
// import React, { FC, useState, useEffect } from "react";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import { Helmet } from "react-helmet";
// import NcModal from "shared/NcModal/NcModal";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
// import NcImage from "shared/NcImage/NcImage";
// import QrCodeImg from "images/qr-code.png";
// import stripe from "images/stripe.svg";
// import razorpay from "images/razorpay.svg";
// import metamask from "../images/metamask_img.png";
// import airdrop from "../images/airdrop.png";
// import walletconnectImg from "images/walletconnect.webp";
// import walletlinkImg from "images/walletlink.webp";
// import fortmaticImg from "images/fortmatic.webp";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { UserGlobalContext } from "user";
// import { useLocation } from "react-router-dom";
// import Axios from "axios";
// import PageLogin from "containers/PageLogin/PageLogin";
// import facebookSvg from "images/Facebook.svg";
// import twitterSvg from "images/Twitter.svg";
// import googleSvg from "images/Google.svg";
// import Input from "shared/Input/Input";
// import { Link } from "react-router-dom";
// import qs from "query-string";
// import Cookies from "universal-cookie";
// import Web3 from "web3";
// import { MetaMaskSDK } from "@metamask/sdk";
// import loading from "../images/loader.gif";
// const MMSDK = new MetaMaskSDK({
//   injectProvider: false,
//   dappMetadata: {
//     name: "United Protocol",
//     url: "https://www.unitedprotocol.org",
//   },
// });
// window.ethereum = MMSDK.getProvider();
// export interface PageConnectWalletProps {
//   className?: string;
// }
// const plans = [
//   {
//     name: "Stripe",
//     img: stripe,
//     api: "stripe2",
//   },
//   {
//     name: "Razorpay",
//     img: razorpay,
//     api: "razorpay2",
//   },
// ];
// const plans1 = [
//   {
//     name: "Airdrop",
//     img: airdrop,
//   },
// ];
// const plans2 = [
//   {
//     name: "Metamask",
//     img: metamask,
//   },
// ];

// const PageConnectWallet: FC<PageConnectWalletProps> = ({ className = "" }) => {
//   const [searchParams] = useSearchParams();
//   const domains = searchParams.get("domains")?.split(",") || [];
//   const totalCost = parseFloat(searchParams.get("totalCost") || "0");
//   const coupon = searchParams.get("coupon") || "";
//   const handleSubmit = (e: any, plan: any) => {
//     e.preventDefault();

//     // Collect multiple domains in an array
//     const selectedDomains = [domain]; // assuming you have multiple domains stored in state or props

//     // Navigate to the airdrop page, passing domains and plan details
//     navigate("/airdrop", {
//       state: {
//         domains: selectedDomains,
//         website: website,
//         coupon: coupon,
//         plan: plan,
//       },
//     });
//   };
//   const cookies = new Cookies();
//   let navigate = useNavigate();
//   const { LoggedIn } = UserGlobalContext();
//   const { state } = useLocation();
//   let exdomain = [];
//   console.log("domain1: " + state.domain);
//   console.log("coupon1: " + state.coupon);
//   let excoupon = "No_Coupon";
//   if (state != null) {
//     exdomain = state.domain;
//     if (state.coupon !== "") {
//       excoupon = state.coupon;
//     }
//   } else {
//     exdomain = decodeURI(window.location.search)
//       .split("?")[1]
//       .split("&")[0]
//       .split("=")[1]
//       .split(",");
//     if (
//       decodeURI(window.location.search)
//         .split("?")[1]
//         .split("&")[1]
//         .split("=")[1] != ""
//     ) {
//       excoupon = decodeURI(window.location.search)
//         .split("?")[1]
//         .split("&")[1]
//         .split("=")[1];
//     }
//   }
//   console.log(exdomain);
//   console.log(excoupon);
//   const [domain, changeDomain] = useState(exdomain);
//   const [website, changeWebsite] = useState("");
//   // const [coupon, changeCoupon] = useState(excoupon);
//   const [cost, changeCost] = useState(-1);
//   const [verifyLoading, setVerifyLoading] = useState("none");
//   useEffect(() => {
//     setVerifyLoading("block");
//     Axios.get("http://localhost:5000/metadata")
//       .then((response) => {
//         changeWebsite(response.data.website);
//       })
//       .catch((err) => console.log(err));
//     async function getCost() {
//       let cost2 = 0;
//       for (let i = 0; i < domain.length; i++) {
//         await Axios.get("http://localhost:5000/search/" + domain[i])
//           // eslint-disable-next-line no-loop-func
//           .then((response) => {
//             console.log(response.data);
//             cost2 += response.data.cost;
//           })
//           .catch(function (err) {
//             console.log(err);
//           });
//       }
//       changeCost(cost2);
//       await Axios.get("http://localhost:5000/coupon/" + coupon)
//         .then((response) => {
//           if (response.data !== "Invalid Code") {
//             console.log("IN");
//             if (response.data[0].amount !== 0) {
//               console.log(response.data[0].amount);
//               cost2 -= response.data[0].amount;
//               changeCost(cost2);
//               // setVerifyLoading("none");
//             } else {
//               console.log(response.data[0].percentage);
//               cost2 -= (cost2 * response.data[0].percentage) / 100;
//               changeCost(cost2);
//               // setVerifyLoading("none");
//             }
//           }
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//     getCost();
//     setVerifyLoading("none");
//   }, [website, coupon, domain]);
//   const [priceofBNB, setPriceBnb] = useState(0.0);

//   function hexEncode(domain3: any) {
//     var result = "";
//     for (var i = 0; i < domain3.length; i++) {
//       result += domain3.charCodeAt(i).toString(16);
//     }
//     return result;
//   }
//   async function register() {
//     if (
//       typeof window.ethereum !== "undefined" &&
//       window.ethereum.isMetaMask === true
//     ) {
//       const currentChainId = await window.ethereum.request({
//         method: "eth_chainId",
//       });
//       if (currentChainId !== "0x38")
//         alert(
//           "Please change your chain to Binance Smart Chain in Metamask for registering!!"
//         );
//       else {
//         await Axios.get(
//           "https://api.binance.com/api/v3/avgPrice?symbol=BNBUSDT"
//         )
//           .then((response) => {
//             // console.log(response.data);
//             setPriceBnb(response.data.price);
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//         const accounts = await window.ethereum
//           .request({ method: "eth_accounts" })
//           .catch((err) => {
//             if (err.code === 4001) {
//               console.log("Please connect to MetaMask.");
//             } else {
//               console.error(err);
//             }
//           });
//         if (accounts !== null && accounts !== undefined) {
//           try {
//             let value = "0";
//             if (cost !== 0) {
//               value = (((cost + 10) / priceofBNB) * Math.pow(10, 18)).toString(
//                 16
//               );
//             } else {
//               value = "0";
//             }
//             const params = [
//               {
//                 from: accounts[0 as keyof typeof accounts],
//                 to: "0x051cC197F17A40edf16d67e3207A18a2E81AE238",
//                 value: value,
//                 gas: "0x5028",
//                 data: "0x" + hexEncode(domain.toString()),
//                 chainId: "56",
//                 chainName:
//                   "BNB Smart Chain (previously Binance Smart Chain Mainnet)",
//                 nativeCurrency: {
//                   name: "Binance Chain Native Token",
//                   symbol: "BNB",
//                   decimals: "18",
//                 },
//                 rpcUrls: ["https://bsc-dataseed.binance.org/"],
//                 blockExplorerUrls: ["https://bscscan.com/"],
//               },
//             ];
//             await window.ethereum
//               .request({ method: "eth_sendTransaction", params })
//               .then(async (result: any) => {
//                 console.log("txnHash is " + result.transactionHash);
//                 await Axios.post("http://localhost:5000/metamask2", {
//                   domain: domain,
//                   website: website,
//                   coupon: coupon,
//                   hash: result.transactionHash,
//                 })
//                   .then((response) => {
//                     console.log(response.data);
//                   })
//                   .catch((err) => {
//                     console.log(err);
//                   });
//               })
//               .catch((err: any) => {
//                 console.log(err);
//               });
//           } catch (e) {
//             console.log("payment fail!");
//             console.log(e);

//             alert(
//               "Can't connect MetaMask. Please check MetaMask. Try to open the extension once and try again."
//             );
//           }
//         } else {
//           alert("No account found!!");
//         }
//       }
//     } else {
//       alert("Please download Metamask Extension and try again!!");
//     }
//   }

//   if (LoggedIn === false) {
//     // alert("Please Log Into Your account then try again!!");
//     // navigate("/login");
//     return null;
//   } else {
//     return (
//       <div
//         className={`nc-PageConnectWallet ${className}`}
//         data-nc-id="PageConnectWallet"
//       >
//         <Helmet>
//           <title>Payment || House of Web3</title>
//         </Helmet>
//         <div className="container">
//           <div className="my-12 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
//             {/* HEADING */}
//             <div className="max-w-2xl">
//               <h2 className="text-3xl sm:text-4xl font-semibold">Payment</h2>
//               <img
//                 src={loading}
//                 style={{ display: verifyLoading }}
//                 className="w-10 h-10"
//               ></img>
//               {/* <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
//               Connect with one of our available wallet providers or create a new
//               one.
//             </span> */}
//             </div>
//             <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
//             <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
//               <div className="space-y-3">
//                 {plans.map((plan) => (
//                   <form
//                     action={"http://localhost:5000/" + plan.api}
//                     method="POST"
//                   >
//                     <button
//                       type="submit"
//                       key={plan.name}
//                       // onClick={() => setShowModal(true)}
//                       typeof="button"
//                       tabIndex={0}
//                       style={{ width: "100%" }}
//                       className="relative pointer-events-none opacity-[0.5] rounded-xl hover:shadow-lg hover:bg-neutral-50 border
//                 border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex
//                 focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800
//                 dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200"
//                     >
//                       <input
//                         required
//                         type="hidden"
//                         name="domain"
//                         value={domain}
//                       />
//                       <input
//                         required
//                         type="hidden"
//                         name="website"
//                         value={website}
//                       />
//                       <input
//                         required
//                         type="hidden"
//                         name="coupon"
//                         value={coupon}
//                       />
//                       <input
//                         required
//                         type="hidden"
//                         name="description"
//                         value={"Payment for buying the domain " + domain}
//                       />
//                       <div className="flex items-center w-full">
//                         <NcImage
//                           src={plan.img}
//                           containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
//                         />
//                         <div
//                           className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl `}
//                         >
//                           {plan.name}
//                         </div>
//                       </div>
//                     </button>
//                   </form>
//                 ))}

//                 {plans1.map((plan) => (
//                   <form
//                     key={plan.name}
//                     // No need for action or method here as we'll handle navigation manually
//                     onSubmit={(e) => handleSubmit(e, plan)}
//                   >
//                     <button
//                       type="submit"
//                       style={{ width: "100%" }}
//                       className="relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border
//       border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex
//       focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800
//       dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200"
//                     >
//                       <input
//                         type="hidden"
//                         name="domain"
//                         value={domain} // Make sure domain is an array of domains
//                       />
//                       <input type="hidden" name="website" value={website} />
//                       <input type="hidden" name="coupon" value={coupon} />
//                       <input
//                         type="hidden"
//                         name="description"
//                         value={`Payment for buying the domain(s) ${domain.join(
//                           ", "
//                         )}`}
//                       />
//                       <div className="flex items-center w-full">
//                         <NcImage
//                           src={plan.img}
//                           containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
//                         />
//                         <div
//                           className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl`}
//                         >
//                           {plan.name}
//                         </div>
//                       </div>
//                     </button>
//                   </form>
//                 ))}

//                 {plans2.map((plan) => (
//                   <button
//                     key={plan.name}
//                     onClick={register}
//                     typeof="button"
//                     tabIndex={0}
//                     style={{ width: "100%" }}
//                     className="relative pointer-events-none opacity-[0.5] rounded-xl hover:shadow-lg hover:bg-neutral-50 border
//                 border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex
//                 focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800
//                 dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200"
//                   >
//                     <div className="flex items-center w-full">
//                       <NcImage
//                         src={plan.img}
//                         containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
//                       />
//                       <div
//                         className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl `}
//                       >
//                         {plan.name}
//                       </div>
//                     </div>
//                   </button>
//                 ))}
//               </div>

//               {/* ---- */}
//               <div className="pt-2 ">
//                 <ButtonPrimary href={"/"} className="flex-1">
//                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
//                     <path
//                       d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"
//                       stroke="currentColor"
//                       strokeWidth="1.5"
//                       strokeMiterlimit="10"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                     <path
//                       d="M20.5 12H3.67004"
//                       stroke="currentColor"
//                       strokeWidth="1.5"
//                       strokeMiterlimit="10"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                   </svg>

//                   <span className="ml-2">Go Back Home</span>
//                 </ButtonPrimary>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// };

// export default PageConnectWallet;
import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { UserGlobalContext } from "user";

const Payment2 = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { darkMode } = UserGlobalContext();

  // Get the parameters from URL
  const domains = searchParams.get("domains")?.split(",") || [];
  const totalCost = parseFloat(searchParams.get("totalCost") || "0");
  const coupon = searchParams.get("coupon");

  // Add state for payment details if needed
  const [paymentMethod, setPaymentMethod] = useState("credit");
  
  if (domains.length === 0) {
    // Redirect to cart if no domains are present
    navigate("/cart");
    return null;
  }

  const handlePaymentSubmit = () => {
    // Navigate to /airdrop with query parameters
    navigate("/airdrop", {
      state: {
        domains,
        cost: totalCost,
        coupon, // Include coupon if necessary
      }
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 dark:text-white">
          Complete Your Purchase
        </h1>

        {/* Order Summary */}
        <div className="bg-gray-50 dark:bg-neutral-800 rounded-lg p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4 dark:text-white">
            Order Summary
          </h2>
          <div className="space-y-4">
            {domains.map((domain, index) => (
              <div
                key={index}
                className="flex justify-between items-center dark:text-white"
              >
                <span>{domain}</span>
              </div>
            ))}
            
            {coupon && (
              <div className="flex justify-between items-center dark:text-white">
                <span>Applied Coupon:</span>
                <span>{coupon}</span>
              </div>
            )}
            
            <div className="border-t pt-4 mt-4">
              <div className="flex justify-between items-center font-semibold dark:text-white">
                <span>Total Amount:</span>
                <span>${totalCost.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Method Selection */}
        {/* <div className="bg-gray-50 dark:bg-neutral-800 rounded-lg p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4 dark:text-white">
            Payment Method
          </h2>
          <div className="space-y-4">
            <label className="flex items-center space-x-3 dark:text-white">
              <input
                type="radio"
                name="payment"
                value="credit"
                checked={paymentMethod === "credit"}
                onChange={(e) => setPaymentMethod(e.target.value)}
                className="form-radio"
              />
              <span>Credit Card</span>
            </label>
            <label className="flex items-center space-x-3 dark:text-white">
              <input
                type="radio"
                name="payment"
                value="debit"
                checked={paymentMethod === "debit"}
                onChange={(e) => setPaymentMethod(e.target.value)}
                className="form-radio"
              />
              <span>Debit Card</span>
            </label>
          </div>
        </div> */}

        {/* Payment Form */}
        {/* <div className="bg-gray-50 dark:bg-neutral-800 rounded-lg p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4 dark:text-white">
            Payment Details
          </h2>
          <form className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1 dark:text-white">
                Card Number
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded dark:bg-neutral-700 dark:text-white"
                placeholder="1234 5678 9012 3456"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1 dark:text-white">
                  Expiry Date
                </label>
                <input
                  type="text"
                  className="w-full p-2 border rounded dark:bg-neutral-700 dark:text-white"
                  placeholder="MM/YY"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1 dark:text-white">
                  CVV
                </label>
                <input
                  type="text"
                  className="w-full p-2 border rounded dark:bg-neutral-700 dark:text-white"
                  placeholder="123"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1 dark:text-white">
                Cardholder Name
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded dark:bg-neutral-700 dark:text-white"
                placeholder="John Doe"
              />
            </div>
          </form>
        </div> */}

        {/* Submit Button */}
        <div className="flex justify-center">
          <ButtonPrimary onClick={handlePaymentSubmit}>
            Airdrop ${totalCost.toFixed(2)}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default Payment2;
