// import React from "react";
// import verify from "../images/mailbox.png";
// import { NavLink } from "react-router-dom";

// export default function PageVerify() {
//   return (
// <div className="mt-15 flex flex-col content-center items-center justify-center rounded overflow-hidden ">
//   <img className="w-96" src={verify} alt="congrats image"></img>
//   <div className="flex flex-col items-center px-10 py-4 ">
//     <div className="font-bold text-4xl my-2">Verify Your Email</div>
//     <p className="py-4 text-base mb-3">
//       You will need to verify your email to complete registration
//     </p>
//     <div className="mb-8">
//       <NavLink
//         rel="noopener noreferrer"
//         className="bg-[#0ac74c] hover:opacity-[0.9] text-dark  py-2 px-4 rounded-full"
//         to="/login"
//       >
//         Login
//       </NavLink>
//     </div>
//   </div>
// </div>
//   );
// }

import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, NavLink } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import verify from "../images/mailbox.png";

const VERIFY_EMAIL_MUTATION = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token) {
      success
      message
    }
  }
`;

const VerifyEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token"); // Get only the token part
  const navigate = useNavigate();
  const [verifyEmail] = useMutation(VERIFY_EMAIL_MUTATION);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (token) {
      setShowMessage(true);
      console.log("Sending token:", token); // Add this line to check if token is available
      verifyEmail({ variables: { token } }) // Send only the token
        .then(({ data }) => {
          if (data.verifyEmail.success) {
            setMessage(
              data.verifyEmail.message || "Email verified successfully!"
            );
            setTimeout(() => navigate("/login"), 3000); // Redirect after success
          } else {
            setError(data.verifyEmail.message || "Failed to verify email.");
          }
        })
        .catch((err) => {
          console.error("Verification error:", err);
          setError(
            "An error occurred while verifying the email. Please try again."
          );
        });
    } else {
      setError("No verification token found.");
      setShowMessage(false);
    }
  }, [token, verifyEmail, navigate]);

  return (
    <div className="mt-15 flex flex-col content-center items-center justify-center rounded overflow-hidden ">
      <img className="w-96" src={verify} alt="congrats image"></img>
      <div className="flex flex-col items-center px-10 py-4 ">
        {showMessage ? (
          <div className="container mb-24 lg:mb-32 text-center">
            <div className="font-bold text-4xl my-2">Email Verification</div>
            {message && <p className="py-4 text-base mb-3">{message}</p>}
            {error && <p className="text-red-600">{error}</p>}
          </div>
        ) : (
          <>
            <div className="font-bold text-4xl my-2">Verify Your Email</div>
            <p className="py-4 text-base mb-3">
              You will need to verify your email to complete registration
            </p>
          </>
        )}

        <div className="mb-8">
          <NavLink
            rel="noopener noreferrer"
            className="bg-[#0ac74c] hover:opacity-[0.9] text-dark  py-2 px-4 rounded-full"
            to="/login"
          >
            Login
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
