import React, { useState } from "react";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import loading from "../images/loading.jpg";

export default function PageNewpassword() {
  const [verifyLoading2, setVerifyLoading2] = useState("none");
  let navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [token, setToken] = useState(
    decodeURI(window.location.search).split("?")[1].split("=")[1]
  );
  function onSubmit() {
    setVerifyLoading2("block");
    if (password === newpassword) {
      axios
        .post("http://localhost:5000/resetpass", {
          password: password,
          newpassword: newpassword,
          token: token,
        })
        .then(function (response) {
        setVerifyLoading2("none");  
          if (response.data === "Password Set!!") {
            navigate("/login");
          } else {
            navigate("/Page404");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/Page404");
        });
    } else {
      alert("Password did not match.");
    }
  }
  return (
    <div className="max-w-md mx-auto my-24">
      {/* FORM */}
      <div className="grid grid-cols-1 gap-6">
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            New Password
          </span>
          <Input
            onChange={(event) => setPassword(event.target.value)}
            name="password"
            type="password"
            placeholder="New Password"
            className="mt-1"
          />
        </label>
        <label className="block">
          <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
            Confirm Password
          </span>
          <Input
            onChange={(event) => setNewpassword(event.target.value)}
            name="newpassword"
            placeholder="Confirm Password"
            type="password"
            className="mt-1"
          />
          <Input
            name="token"
            type="hidden"
            value={
              decodeURI(window.location.search).split("?")[1].split("=")[1]
            }
          />
        </label>
        <ButtonPrimary onClick={onSubmit}>Continue</ButtonPrimary>
        <img
          src={loading}
          style={{ display: verifyLoading2 }}
          className=" w-6 h-6"
        ></img>
      </div>
    </div>
  );
}
