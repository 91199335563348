import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import NextPrev from "shared/NextPrev/NextPrev";
import NcImage from "shared/NcImage/NcImage";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import LikeButton from "components/LikeButton";
import ItemTypeVideoIcon from "components/ItemTypeVideoIcon";
import { nftsLargeImgs } from "contains/fakeData";
import TimeCountDown from "./TimeCountDown";
import collectionPng from "images/nfts/collection.png";
import VerifyIcon from "components/VerifyIcon";
import Heading from "components/Heading/Heading";
import { DownloadIcon } from "@heroicons/react/solid";
export interface CardLarge1Props {
  className?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  isShowing?: boolean;
  featuredImgUrl?: string;
}

const CardLarge1: FC<CardLarge1Props> = ({
  className = "",
  isShowing = true,
  onClickNext = () => {},
  onClickPrev = () => {},
  featuredImgUrl = nftsLargeImgs[0],
}) => {
  const navigate = useNavigate()
  const randomTitle = ["DamGo "];
  function handleClick() {
    navigate("/contact")
  }
  return (
    <div
      className={`nc-CardLarge1 nc-CardLarge1--hasAnimation z-0 relative flex flex-col-reverse lg:flex-row justify-end ${className}`}
    >
      <div className="lg:absolute z-10 lg:left-0 lg:top-1/2 lg:transform lg:-translate-y-1/2 -mt-2 lg:mt-0 sm:px-5 lg:px-0 w-full lg:max-w-lg ">
        <div className="nc-CardLarge1__left p-4 text-center sm:p-8 xl:py-14 md:px-10 bg-white dark:bg-neutral-900 shadow-lg rounded-3xl  ">
          {/* TITLE */}
          <h2 className="text-2xl lg:text-3xl 2xl:text-4xl font-semibold text-center mb-5">
            {/* <Heading title="JOVO"></Heading> */}
            DamGo Web3 Browser
          </h2>
<p className="mt-2">Browse to earn</p>
<p className="my-">Play to earn</p>
<p className="my-">Watch to earn</p>
<p className="mt-2">No extension required</p>
<p className="mb-1">No DNS setting required</p>
<p className="mb-5">Open more than 10mn web3 domains</p>
          {/* AUTHOR AND COLLECTION */}
          {/* <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-12">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <Avatar sizeClass="w-10 h-10" />
              </div>
              <div className="ml-3">
                <div className="text-xs dark:text-neutral-400">Creator</div>
                <div className="text-sm font-semibold flex items-center">
                  <span>Jane Cooper</span>
                  <VerifyIcon />
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <Avatar sizeClass="w-10 h-10" imgUrl={collectionPng} />
              </div>
              <div className="ml-3">
                <div className="text-xs dark:text-neutral-400">Collection</div>
                <div className="text-sm font-semibold ">Marscapes</div>
              </div>
            </div>
          </div> */}

          {/* PRICE */}

          <div className="mb-5 flex flex-col sm:flex-row justify-center py-6 border-2 border-[#0ac74c] rounded-xl ">
            <span className=" text-3xl xl:text-4xl font-semibold text-[#0ac74c]">
              .apk .dmg .exe
            </span>
          </div>

          {/* AUTION TIME */}
          {/* <TimeCountDown /> */}

          {/* <div className="w h-[1px] bg-neutral-100 dark:bg-neutral-700"></div> */}

          {/* DESCRIPTION */}
          <div className="justify-center text-center flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
          <button className="flex flex-row bg-[#0ac74c] hover:opacity-[0.9] text-black  py-2 px-4 rounded-full" onClick={handleClick}><DownloadIcon className="w-4 h-6"/>Download DamGo</button>
          </div>
        </div>
        {/* <div className="p-4 sm:pt-8 sm:px-10 ">
          <NextPrev
            btnClassName="w-11 h-11 text-xl"
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
        </div> */}
      </div>

      <div className="w-full lg:w-[64%] relative ">
        <div className="nc-CardLarge1__right ">
          <Link to={" "}>
            <NcImage
              containerClassName="aspect-w-1 aspect-h-1 relative"
              className="absolute inset-0 object-cover rounded-3xl sm:rounded-[40px] border-4 sm:border-[14px] border-white dark:border-neutral-800"
              src={featuredImgUrl}
              alt={"title"}
            />
          </Link>

          {/* META TYPE */}
          {/* <ItemTypeVideoIcon className="absolute w-8 h-8 md:w-10 md:h-10 left-3 bottom-3 sm:left-7 sm:bottom-7 " /> */}

          {/* META FAVORITES */}
          {/* <LikeButton className="absolute right-3 top-3 sm:right-7 sm:top-7" /> */}
        </div>
      </div>
    </div>
  );
};

export default CardLarge1;
