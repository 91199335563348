import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import {Link} from "react-router-dom";



export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}
const d = new Date();
let year = d.getFullYear();
const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Getting started",
    menus: [
      { href: "/", label: "Home" },
      // { href: '/#benefits', label: "Features" },
      // { href: "/roadmap", label: "Roadmap" },
      // { href: "/#tutorial", label: "Tutorials" },
      { href: "#", label: "Documentation" },
    ],
  },
  {
    id: "2",
    title: "Domains",
    menus: [
      { href: "/", label: "Domain Name Search" },
      //toref: "", label: "Transfer" },
      { href: "/personal-domain", label: "Personal Domain" },
      // { href: "/#premium-domains", label: "Premium Domains" },
    ],
  },
  {
    id: "3",
    title: "Support",
    menus: [
      { href: "/contact", label: "Contact Us" },
      // { href: "/contact", label: "Feedback" },
      // { href: "/about#team", label: "Team" },
      { href: "/#faq", label: "FAQ" },
    ],
  },
  {
    id: "4",
    title: "Products",
    menus: [
      { href: "https://www.unitedprotocol.org", label: "United Protocol" },
      // { href: "/thenextinternet", label: "The Next Internet" },
      // { href: "/thenextinternet#sustainable-blockchain", label: "Sustainable Blockchain" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <Link
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                to={item.href}
                target="_self"
                rel="noopener noreferrer"
              >
                {item.label}
                
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  if(window.location.pathname!=="/design"){

  
  return (
    <div className="nc-Footer relative py-4 lg:pt-16  border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>

      <div className="container mt-10">
      <span className="text-gray-400"> Copyright © {year}. All Rights Reserved To United Protocol</span>
      <a className="mx-12" href="/privacypolicy">Privacy Policy</a>
      <a href="/terms">Terms</a>
      </div>
    </div>
  );
  }
  else {
    return null;
  }
};

export default Footer;
