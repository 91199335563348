import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Congratulations from "../images/congrats.gif";
// import { useLocation } from "react-router-dom";
import qs from "query-string";
import verify from '../images/mailbox.png'


function LoginSuccess() {
  //   const currentUrl = window.location.href;
  //   const urlParts = currentUrl.split("?")[1] && currentUrl.split("=")[2] && currentUrl.split("&")[0];
  //   const domain = urlParts;
  //     console.log("loginWind", domain);
  // Get the current URL
  const currentUrl = window.location.href;

  // Split the URL by "?" to separate the base URL from the query parameters
  const parts = currentUrl.split("?");

  // Initialize variables to store the domain and coupon values
  let domain = "";
  let coupon = "";

  // Check if there are query parameters
  if (parts.length > 1) {
    // Split the query parameters by "&" to separate individual parameters
    const queryParams = parts[1].split("&");

    // Iterate through the query parameters to find the "domain" and "coupon" parameters
    for (const param of queryParams) {
      const [key, value] = param.split("=");

      // Check if the parameter key is "domain"
      if (key === "domain") {
        domain = decodeURIComponent(value);
      }

      // Check if the parameter key is "coupon"
      if (key === "coupon") {
        coupon = decodeURIComponent(value);
      }
    }
  }

  const navigate = useNavigate();
  console.log("Domain:", domain);
  console.log("Coupon:", coupon);
  function handleClick() {
    navigate(`/payment?domain=${domain}&coupon=${coupon}`, {
      state: { domain: domain, coupon: coupon },
    });
  }

  // const {state} = useLocation()
  // console.log("logins",state.domain)
  return (
    <div className="mt-15 flex flex-col content-center items-center justify-center rounded overflow-hidden ">
    <img className="w-96" src={verify} alt="congrats image"></img>
      <div className="flex flex-col items-center px-10 py-4 ">
        <div className="font-bold text-4xl my-6">
          You have successfully login!
        </div>
        {/* <p className="py-4 text-base mb-3">
         
          Request sent successfully
        </p> */}
        <div className="mb-8">
          <button
            rel="noopener noreferrer"
            className="bg-[#0ac74c] text-black hover:opacity-[0.9] text-dark  py-2 px-4 rounded-full"
            onClick={handleClick}
          >
            Go To Payment
          </button>
        </div>
      </div>
    </div>
  );
}
export default LoginSuccess;
// https://img.icons8.com/bubbles/200/000000/trophy.png
