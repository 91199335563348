import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import facebook from "images/socials/facebook.svg";
import vimeo from "images/socials/vimeo.svg";
import twitter from "images/socials/twitter.svg";
import instagram from "images/instagram.svg";
import youtube from "images/socials/youtube.svg";

export interface SocialsList1Props {
  className?: string;
}

const socials: SocialType[] = [
  //{ name: "LinkedIn", icon: facebook, href: "https://www.linkedin.com/company/United Protocol-house-of-web3/" },
  // { name: "Vimeo", icon: vimeo, href: "#" },
  //{ name: "Youtube", icon: youtube, href: "https://www.youtube.com/@how3official" },
  { name: "Instagram", icon: instagram, href: "https://www.instagram.com/unitedecosystem/" },
  { name: "Twitter", icon: twitter, href: "https://twitter.com/UnitedEcosystem" },
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-3" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <div className="flex-shrink-0 w-5 ">
          <img src={item.icon} alt="" />
        </div>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
