import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate } from "react-router-dom";
import CartItemsList from "./CartItemsList";
import emptycart2 from "../../images/cartWhite.png";
import emptycart1 from "../../images/cartBlack.png";
import { UserGlobalContext } from "user";
import { gql, useLazyQuery } from "@apollo/client";

interface CouponData {
  message: string;
  coupon: {
    code: string;
    amount?: number;
    discountPercentage?: number;
  } | null;
}

const VERIFY_COUPON = gql`
  query VerifyCoupon($code: String!) {
    verifyCoupon(code: $code) {
      message
      coupon {
        code
        amount
        discountPercentage
      }
    }
  }
`;

const STATIC_DOMAIN_COST = 12; // Static cost per domain

const Ordersum3 = () => {
  const { LoggedIn } = UserGlobalContext();
  const { changeCartItems, changeArray, array, darkMode } = UserGlobalContext();
  const [verifyCoupon, { data: couponData }] = useLazyQuery<{ verifyCoupon: CouponData }>(VERIFY_COUPON);
  
  useEffect(() => {
    changeCartItems(array.length);
  }, [array, changeCartItems]);

  // Calculate total cost based on static price
  const calculateTotalCost = () => {
    return array.length * STATIC_DOMAIN_COST;
  };

  let navigate = useNavigate();
  const [cost, changeCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  // Update total cost whenever array changes
  useEffect(() => {
    const newTotalCost = calculateTotalCost();
    setTotalCost(newTotalCost);
    changeCost(newTotalCost);
  }, [array]);

  const [coupon, changeCoupon] = useState("");
  const [couponApplied, changeCouponApplied] = useState("No Coupon!!");

  function HandleClick() {
    if (LoggedIn === false) {
      alert("Please Log Into Your Account!!");
      navigate("/login");
    } else {
      const queryParams = new URLSearchParams();
      queryParams.append("domains", array.join(","));
      queryParams.append("totalCost", cost.toString());
      
      if (couponApplied !== "No Coupon!!") {
        queryParams.append("coupon", couponApplied);
      }

      navigate(`/payment2?${queryParams.toString()}`);
    }
  }

  function handleClickChild(event: any) {
    const updatedArray = [...array];
    updatedArray.splice(event.target.value, 1);
    changeArray(updatedArray);
    changeCartItems(updatedArray.length);
    document.cookie = "cartItem = " + JSON.stringify(updatedArray);
  }

  function HandleChange(event: any) {
    changeCoupon(event.target.value);
  }

  async function VerifyCoupon() {
    try {
      const { data } = await verifyCoupon({
        variables: { code: coupon }
      });

      if (!data) return;

      const { message, coupon: couponResult } = data.verifyCoupon;

      if (message === "Invalid Code" || message === "Coupon Expired") {
        changeCost(totalCost);
        alert(message);
        return;
      }

      if (couponResult) {
        if (couponResult.amount) {
          const newCost = totalCost - couponResult.amount;
          changeCost(newCost);
        } else if (couponResult.discountPercentage) {
          const discount = (couponResult.discountPercentage * totalCost) / 100;
          const newCost = totalCost - discount;
          changeCost(newCost);
        }
        alert("Coupon Applied Successfully!");
        changeCouponApplied(coupon);
      }
    } catch (error) {
      console.error("Error verifying coupon:", error);
      alert("Error verifying coupon. Please try again.");
    }
  }

  useEffect(() => {
    const checkbox = document.getElementById("link-checkbox") as HTMLInputElement;
    const giftDiv = document.getElementById("giftDiv") as HTMLDivElement;
    if (checkbox !== null && giftDiv !== null) {
      giftDiv.style.display = "none";
      if (checkbox) {
        checkbox.addEventListener("change", function () {
          if (checkbox.checked) {
            giftDiv.style.display = "block";
          } else {
            giftDiv.style.display = "none";
          }
        });
      }
    }
  }, []);

  if (array.length === 0) {
    return (
      <div className="my-36">
        <div className="m-auto text-4xl flex gap-10 text-center flex-col items-center justify-center">
          {darkMode ? (
            <img className="w-72 h-72" alt="Cart Empty" src={emptycart2} />
          ) : (
            <img className="w-72 h-72" alt="Cart Empty" src={emptycart1} />
          )}
          Your Cart is Empty!!
          <div className="mt-4">
            <ButtonPrimary>
              <Link to="/">Continue Shopping</Link>
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
        <div className="flex justify-start item-start space-y-2 flex-col">
          <h1 className="text-3xl lg:text-4xl font-semibold leading-7 lg:leading-9 dark:text-neutral-50 text-gray-800">
            Order Summary
          </h1>
        </div>
        <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
          <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
            <div className="dark:bg-neutral-800 flex flex-col justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
              <p className="dark:text-neutral-50 text-lg md:text-xl font-semibold leading-6 xl:leading-5 text-gray-800">
                Customer's Cart
              </p>

              {array.map((item: any, index: any) => (
                <CartItemsList
                  id={index}
                  key={index}
                  change={handleClickChild}
                  name={item}
                  cost={STATIC_DOMAIN_COST}
                />
              ))}
            </div>

            <div className="flex justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
              <div className="dark:bg-neutral-800 flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6">
                <h3 className="dark:text-neutral-50 text-xl font-semibold leading-5 text-gray-800">
                  Discount : ${(totalCost - cost).toFixed(2)}
                </h3>
                <p className="dark:text-neutral-50 font-semibold text-gray-800">
                  Enter Discount Coupon Here
                </p>
                <div className="flex flex-row w-full">
                  <div className="w-full flex">
                    <div className="w-full flex border-b border-teal-500 pt-2">
                      <input
                        onChange={HandleChange}
                        value={coupon}
                        className="dark:text-neutral-50 bg-transparent border-none mr-1 w-full text-gray-700"
                        type="text"
                        placeholder="Add coupon code"
                        aria-label=""
                      />
                    </div>
                    <ButtonSecondary onClick={VerifyCoupon}>
                      Verify
                    </ButtonSecondary>
                  </div>
                </div>
              </div>
              <div className="dark:bg-neutral-800 flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6">
                <h3 className="dark:text-neutral-50 text-xl font-semibold leading-5 text-gray-800">
                  Summary
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                  <div className="flex justify-between w-full">
                    <p className="dark:text-neutral-50 text-base leading-4 text-gray-800">
                      Subtotal
                    </p>
                    <p className="dark:text-neutral-50 text-base leading-4 text-gray-600">
                      ${totalCost}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="dark:text-neutral-50 text-base leading-4 text-gray-800">
                      Discount
                    </p>
                    <p className="dark:text-neutral-50 text-base leading-4 text-gray-600">
                      -{(((totalCost - cost) * 100) / totalCost).toFixed(2)}%
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="dark:text-neutral-50 text-base font-semibold leading-4 text-gray-800">
                    Total
                  </p>
                  <p className="dark:text-neutral-50 text-base font-semibold leading-4 text-gray-600">
                    ${cost}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full flex justify-center pt-8 items-center">
              <ButtonPrimary onClick={HandleClick}>
                Continue to payment
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ordersum3;