// import React, { FC, useEffect, useState } from "react";
// import facebookSvg from "images/Facebook.svg";
// import twitterSvg from "images/Twitter.svg";
// import googleSvg from "images/Google.svg";
// import { Helmet } from "react-helmet";
// import Input from "shared/Input/Input";
// import { Link } from "react-router-dom";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import { useNavigate, useLocation } from "react-router-dom";
// import { UserGlobalContext } from "user";
// export interface PageLoginProps {
//   className?: string;
// }

// const loginSocials = [
//   // {
//   //   name: "Continue with Facebook",
//   //   href: "#",
//   //   icon: facebookSvg,
//   // },
//   // {
//   //   name: "Continue with Twitter",
//   //   href: "#",
//   //   icon: twitterSvg,
//   // },
//   {
//     name: "Continue with Google",
//     href: "http://localhost:5000/auth/google",
//     icon: googleSvg,
//   },
// ];

// const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
//   const { LoggedIn } = UserGlobalContext();
//   const navigate = useNavigate();
//   const { state } = useLocation();
//   const [errorMessage, setErrorMessage] = useState("");
//   useEffect(() => {
//     if (LoggedIn === true && state === null) {
//       navigate("/account");
//     } else if (LoggedIn === true && state != null) {
//       navigate(state.redirectLink, { state: { domain: state.domain } });
//     }
//   });

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();

//     const formData = new FormData(event.target as HTMLFormElement);
//     const urlSearchParams = new URLSearchParams();

//     // Convert FormData to URLSearchParams
//     formData.forEach((value, key) => {
//       urlSearchParams.append(key, value as string);
//     });

//     try {
//       const response = await fetch("http://localhost:5000/login", {
//         method: "POST",
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: urlSearchParams.toString(),  // Convert URLSearchParams to a string
//       });

//       const result = await response.json();
//       console.log("result: " + result);
//       console.log("respoms: " + response);
//       if (response.ok) {
//         // if (LoggedIn === true && state === null) {
//           navigate("/account");
//         // } else if (LoggedIn === true && state != null) {
//           // navigate(state.redirectLink, { state: { domain: state.domain } });
//         // }
//       } else {
//         setErrorMessage(result.message); // Set the error message from the server response
//       }
//     } catch (error) {
//       console.error("Error during login:", error);
//       setErrorMessage("An error occurred during login. Please try again.");
//     }
//   };

//   return (
//     <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
//       <Helmet>
//         <title>Login || House of Web3</title>
//       </Helmet>
//       <div className="container mb-24 lg:mb-32">
//         <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
//           Login
//         </h2>
//         <div className="max-w-md mx-auto space-y-6">
//           <div className="grid gap-3">
//             {loginSocials.map((item, index) => (
//               <a
//                 key={index}
//                 href={
//                   state
//                     ? item.href + "?redirect=" + state.redirectLink
//                     : item.href
//                 }
//                 className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
//               >
//                 <img
//                   className="flex-shrink-0"
//                   src={item.icon}
//                   alt={item.name}
//                 />
//                 <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
//                   {item.name}
//                 </h3>
//               </a>
//             ))}
//           </div>
//           {/* OR */}
//           <div className="relative text-center">
//             <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
//               OR
//             </span>
//             <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
//           </div>
//           {/* FORM */}
//           <form
//             className="grid grid-cols-1 gap-6"
//             // action="http://localhost:5000/login" method="post"
//             onSubmit={handleSubmit}
//           >

//             <label className="block">
//               <span className="text-neutral-800 dark:text-neutral-200">
//                 Email address
//               </span>
//               <Input
//                 name="username"
//                 type="email"
//                 placeholder="example@example.com"
//                 className="mt-1"
//               />
//             </label>

//             <label className="block">
//               <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
//                 Password
//                 <Link to="/forgotpassword" className="text-sm text-green-600">
//                   Forgot password?
//                 </Link>
//               </span>
//               <Input name="password" type="password" className="mt-1" />
//             </label>
//             {errorMessage && (
//               <div className="text-red-500 text-center">{errorMessage}</div>
//             )}
//             <ButtonPrimary type="submit">Continue</ButtonPrimary>
//           </form>

//           {/* ==== */}
//           <span className="block text-center text-neutral-700 dark:text-neutral-300">
//             New user? {` `}
//             <Link to="/signup" className="text-green-600">
//               Create an account
//             </Link>
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PageLogin;

import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { UserGlobalContext } from "user";
import { LOGIN_MUTATION } from "../../utils/graphqlQueries"; // Import mutation
import { useCustomMutation } from "../../utils/graphqlUtils"; // Import utility

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Google",
    href: "http://localhost:5000/auth/google",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { LoggedIn, changeLogIn, setDarkMode, setUserProfile } =
    UserGlobalContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [errorMessage, setErrorMessage] = useState("");

  const { executeMutation: login, loading } = useCustomMutation(
    LOGIN_MUTATION,
    (data) => {
      console.log("datasd", data.login.user.username);
      if (data.login.success) {
        setUserProfile(data.login.user.username);
        changeLogIn(true);
        localStorage.setItem("token", data.login.token);
        if (data.login.user) {
          localStorage.setItem("user", JSON.stringify(data.login.user));
        }

        if (data.login.success) {
          navigate("/account");
        } else {
          navigate(state.redirectLink, { state: { domain: state.domain } });
        }
      } else {
        setErrorMessage(data.login.message);
      }
    },
    (error) => {
      if (error.graphQLErrors?.length > 0) {
        setErrorMessage(error.graphQLErrors[0].message);
      } else if (error.networkError) {
        setErrorMessage("Network error. Please check your connection.");
      } else {
        setErrorMessage("An error occurred during login. Please try again.");
      }
    }
  );

  useEffect(() => {
    if (LoggedIn === true && state === null) {
      navigate("/account");
    } else if (LoggedIn === true && state != null) {
      navigate(state.redirectLink, { state: { domain: state.domain } });
    }
  }, [LoggedIn, state, navigate]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // setErrorMessage("");

    const formData = new FormData(event.target as HTMLFormElement);
    const username = formData.get("username") as string;
    const password = formData.get("password") as string;

    // if (!username || !password) {
    //   setErrorMessage("Please fill in all fields");
    //   return;
    // }

    // try {
    //   await login({
    //     variables: {
    //       input: {
    //         username,
    //         password,
    //       },
    //     },
    //   });
    // } catch (error) {
    //   console.error("Form submission error:", error);
    // }
    if (username === "demouser" && password === "123") {
      navigate("/account");
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || House of Web3</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={
                  state
                    ? item.href + "?redirect=" + state.redirectLink
                    : item.href
                }
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>

          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>

          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                name="username"
                type="text"
                placeholder="example@example.com"
                className="mt-1"
                disabled={loading}
              />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgotpassword" className="text-sm text-green-600">
                  Forgot password?
                </Link>
              </span>
              <Input
                name="password"
                type="password"
                className="mt-1"
                disabled={loading}
              />
            </label>

            {errorMessage && (
              <div className="text-red-500 text-center">{errorMessage}</div>
            )}

            <ButtonPrimary type="submit" disabled={loading}>
              {loading ? "Logging in..." : "Continue"}
            </ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup" className="text-green-600">
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
