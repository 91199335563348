import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";
import Checkbox from "shared/Checkbox/Checkbox";
import { BadgeCheckIcon, CheckCircleIcon, CheckIcon } from "@heroicons/react/solid";
import { Checkboard } from "react-color";
import { CFormCheck } from "@coreui/react";

const Ordersum3 = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  let exdomain = "";
  let exextension = "";
  if (state != null) {
    exdomain = state.domain;
    exextension = state.domain.split(".")[1];
  }
  const [domain, changeDomain] = useState(exdomain);
  const [extension, changeExtension] = useState(exextension);
  const [domainData, changeDomainData] = useState({
    cost: 0,
    type: "Getting Data...",
  });
  const [coupon, changeCoupon] = useState("No Coupon");
  const [couponApplied, changeCouponApplied] = useState("No Coupon!!");
  const [cost, changeCost] = useState(domainData.cost);

  console.log("urld",state.domain)

  useEffect(() => {
    Axios.get("http://localhost:5000/search/" + domain)
      .then((response) => {
        changeDomainData(response.data);
        changeCost(response.data.cost);
      })
      .catch((err) => console.log(err));
  }, [domain]);
  console.log("coup",coupon)
  function HandleClick() {
    // navigate("/payment", { state: { domain: domain, coupon: coupon } });
    // navigate("/payment", { state: { domain: domain } });
    navigate(`/payment?domain=${domain}&coupon=${coupon}&cost=${cost}`, { state: { domain: domain, coupon: coupon, cost: cost } });
  }
  function HandleChange(event: any) {
    changeCoupon(event.target.value);
  }
  // function VerifyCoupon() {
  //   changeCouponApplied(coupon);
  // }
  function VerifyCoupon() {
    console.log("coupon", coupon);
    Axios.get(`http://localhost:5000/couponVerify/${coupon}`)
      .then((response) => {
        console.log("response", response);
        if (response.data.message === "Invalid Code") {
          changeCost(domainData.cost);
          alert("Wrong Code!!");
        } else if (response.data.amount) {
          const newCost = domainData.cost - response.data.amount;
          changeCost(newCost);
          alert("Code Applied!!");
        } else if (response.data.percentage) {
          // If discount is a percentage
          const newCost = domainData.cost - (domainData.cost * response.data.percentage / 100);
          changeCost(newCost);
          alert("Code Applied!!");
        }
        changeCouponApplied(coupon);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  // useEffect(() => {
  //   Axios.get("http://localhost:5000/coupon/" + coupon)
  //     .then((response) => {
  //       if (response.data === "Invalid Code") {
  //         changeCost(domainData.cost);
  //         alert("Wrong Code!!");
  //       } else if (response.data[0].amount != 0) {
  //         const temp = domainData.cost - response.data[0].amount;
  //         console.log(temp);
  //         changeCost(temp);
  //         alert("Code Applied!!");
  //       } else {
  //         const temp =
  //           domainData.cost -
  //           (response.data[0].percentage * domainData.cost) / 100;
  //         changeCost(temp);
  //         alert("Code Applied!!");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [couponApplied, domainData.cost]);
  return (
    <div className="container">
      <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
        <div className="flex justify-start item-start space-y-2 flex-col ">
          <h1 className="text-3xl lg:text-4xl font-semibold leading-7 lg:leading-9  dark:text-neutral-50 text-gray-800">
            Order Summary
          </h1>
          {/* <p className="text-base font-medium leading-6 dark:text-neutral-50 text-gray-600">
          31st December 2022 at 10:34 PM
        </p> */}
        </div>
        <div className=" mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch  w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
          <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
            <div className="dark:bg-neutral-800 flex flex-col justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
              <p className="dark:text-neutral-50 text-lg md:text-xl font-semibold leading-6 xl:leading-5 text-gray-800">
                Customer’s Cart
              </p>

              <div className="mt-4 md:mt-6 flex  flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full ">
                {/* <div className="pb-4 md:pb-8 w-full md:w-40">
                                <img className="w-full hidden md:block" src="https://i.ibb.co/84qQR4p/Rectangle-10.png" alt="dress" />
                                <img className="w-full md:hidden" src="https://i.ibb.co/L039qbN/Rectangle-10.png" alt="dress" />
                            </div> */}
                <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full  pb-8 space-y-4 md:space-y-0">
                  <div className="w-full flex flex-col justify-start items-start space-y-8">
                    <h3 className="dark:text-neutral-50 text-xl xl:text-2xl font-semibold leading-6 text-gray-800">
                      {domain}
                    </h3>
                    <div className="flex justify-start items-start flex-col space-y-2">
                      <p className="dark:text-neutral-50 text-sm leading-none text-gray-800">
                        <span className="text-gray-400">Extension: </span>{" "}
                        {extension}
                      </p>
                      {/* <p className="text-sm leading-none text-gray-800">
                                            <span className="text-gray-300">Size: </span> Small
                                        </p>
                                        <p className="text-sm leading-none text-gray-800">
                                            <span className="text-gray-300">Color: </span> Light Blue
                                        </p> */}
                    </div>
                  </div>
                  <div className="flex justify-between space-x-8 items-start w-full">
                    <p className="dark:text-neutral-50 text-base xl:text-lg leading-6 text-gray-800">
                      ${cost}{" "}
                      <span className="text-red-300 line-through">
                        {" "}
                        ${domainData.cost}
                      </span>
                    </p>
                    <p className="dark:text-neutral-50 text-base xl:text-lg leading-6 text-gray-800"></p>
                    <p className="dark:text-neutral-50 text-base xl:text-lg font-semibold leading-6 text-gray-800">
                      {domainData.type}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="mt-6 md:mt-0 flex justify-start flex-col md:flex-row  items-start md:items-center space-y-4  md:space-x-6 xl:space-x-8 w-full ">
                            <div className="w-full md:w-40">
                                <img className="w-full hidden md:block" src="https://i.ibb.co/s6snNx0/Rectangle-17.png" alt="dress" />
                                <img className="w-full md:hidden" src="https://i.ibb.co/BwYWJbJ/Rectangle-10.png" alt="dress" />
                            </div>
                            <div className="  flex justify-between items-start w-full flex-col md:flex-row space-y-4 md:space-y-0  ">
                                <div className="w-full flex flex-col justify-start items-start space-y-8">
                                    <h3 className="text-xl xl:text-2xl font-semibold leading-6 text-gray-800">High Quaility Italic Dress</h3>
                                    <div className="flex justify-start items-start flex-col space-y-2">
                                        <p className="text-sm leading-none text-gray-800">
                                            <span className="text-gray-300">Style: </span> Italic Minimal Design
                                        </p>
                                        <p className="text-sm leading-none text-gray-800">
                                            <span className="text-gray-300">Size: </span> Small
                                        </p>
                                        <p className="text-sm leading-none text-gray-800">
                                            <span className="text-gray-300">Color: </span> Light Blue
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between space-x-8 items-start w-full">
                                    <p className="text-base xl:text-lg leading-6">
                                        $20.00 <span className="text-red-300 line-through"> $30.00</span>
                                    </p>
                                    <p className="text-base xl:text-lg leading-6 text-gray-800">01</p>
                                    <p className="text-base xl:text-lg font-semibold leading-6 text-gray-800">$20.00</p>
                                </div>
                            </div>
                        </div> */}
            </div>

            <div  className="flex   justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
              <div className="dark:bg-neutral-800  flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
                <h3 className="dark:text-neutral-50 text-xl font-semibold leading-5 text-gray-800">
                  Discount : ${(domainData.cost - cost).toFixed(2)}
                </h3>
                <p className="dark:text-neutral-50 font-semibold text-gray-800">
                  Enter Discount Coupon Here
                </p>
                <div className="flex flex-row w-full">
                  {/* <div className="flex justify-center items-center space-x-4"> */}
                  {/* <div className="flex flex-col justify-start items-center"> */}

                  {/* <span className="font-normal">Some content</span> */}
                  <div className="w-full  flex">
                    <div className="w-full flex border-b border-teal-500 pt-2">
                      <input
                        onChange={HandleChange}
                        value={coupon}
                        className="dark:text-neutral-50 bg-transparent border-none mr-1  w-full text-gray-700 "
                        type="text"
                        placeholder="Add coupon code"
                        aria-label=""
                      />
                    </div>
                    <ButtonSecondary onClick={VerifyCoupon}>
                      Verify
                    </ButtonSecondary>
                  </div>

                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
              <div className="dark:bg-neutral-800 flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
                <h3 className="dark:text-neutral-50 text-xl font-semibold leading-5 text-gray-800">
                  Summary
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                  <div className="flex justify-between  w-full">
                    <p className="dark:text-neutral-50 text-base leading-4 text-gray-800">
                      Subtotal
                    </p>
                    <p className="dark:text-neutral-50 text-base leading-4 text-gray-600">
                      ${domainData.cost}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="dark:text-neutral-50 text-base leading-4 text-gray-800">
                      Discount
                      {/* <span className="bg-gray-200 p-1 text-xs font-medium leading-3  text-gray-800">STUDENT</span> */}
                    </p>
                    <p className="dark:text-neutral-50 text-base leading-4 text-gray-600">
                      -{(((domainData.cost - cost) * 100) / domainData.cost).toFixed(2)} %
                    </p>
                  </div>
                  {/* <div className="flex justify-between items-center w-full">
                                    <p className="text-base leading-4 text-gray-800">Shipping</p>
                                    <p className="text-base leading-4 text-gray-600">$8.00</p>
                                </div> */}
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="dark:text-neutral-50 text-base font-semibold leading-4 text-gray-800">
                    Total
                  </p>
                  <p className="dark:text-neutral-50 text-base font-semibold leading-4 text-gray-600">
                    ${cost}
                  </p>
                </div>
              </div>
            </div>

            
            {/* Gift */}

            {/* <div className="flex items-center">
        <input id="link-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-black-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
        <label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">I want to gift this domain to someone else.</label>
      </div>


    <div  id="giftDiv" className="dark:bg-neutral-800 flex flex-col justify-start items-start bg-gray-50 py-4 md:py-6 px-5  w-full">
              <p className="dark:text-neutral-50 text-lg md:text-xl font-semibold leading-6 xl:leading-5 text-gray-800">
                Gift
              </p>
              <div className="mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center w-full ">
                <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full  pb-8 space-y-4 md:space-y-0">
                  <div className="w-full flex flex-col justify-start items-start space-y-8">
                    <h3 className="dark:text-neutral-50 text-xl xl:text-2xl font-semibold leading-6 text-gray-800">
                      {domain}
                    </h3>
                    <div className="flex justify-start items-start flex-col space-y-2">
                      <p className="dark:text-neutral-50 text-md leading-none text-gray-800">
                        Gift {domain} to someone.
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between mx-3  items-start w-full">
                  <p className="dark:text-neutral-50 xl:text-lg mt-3 font-semibold  text-gray-800">
                    Email
                  </p>
                    <div className="w-full flex border-b mt-1 ml-1 border-teal-500 ">
                      <input
                        className="dark:text-neutral-50 bg-transparent border-none  w-full text-gray-700 "
                        type="email"
                        placeholder="Add recipient email"
                        aria-label=""
                      />
                    </div>
                  </div>
                  <div className="flex justify-between  items-start w-full">
                  <p className="dark:text-neutral-50 xl:text-lg mt-3 font-semibold  text-gray-800">
                    Message
                  </p>
                    <div className="w-full flex border-b mt-1 border-teal-500 ">
                      <input
                        className="dark:text-neutral-50 bg-transparent border-none  mx-1 w-full text-gray-700 "
                        type="text"
                        placeholder="Add a personalised message"
                        aria-label=""
                      />
                    </div>
                  </div>
                    
                    <ButtonSecondary className="ml-1" >
                      Save
                    </ButtonSecondary>
                </div>
              </div>
            </div> */}
            <div className="w-full flex justify-center pt-8 items-center">
              <ButtonPrimary
                onClick={() => {
                  if ((domainData.type = "Not Available")) {
                    HandleClick();
                  } else {
                    alert(
                      "This domain is Not Available, Please buy another Domain!!"
                    );
                  }
                }}
              >
                Continue to payment
              </ButtonPrimary>
              {/* <button className="hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-5 w-96 md:w-full bg-gray-800 text-base font-medium leading-4 text-white">Buy now</button> */}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Ordersum3;
