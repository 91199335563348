import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/rightLargeImg.jpg";
import rightLargeImgDark from "images/rightLargeImgDark.jpg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { HashScroll } from "react-hash-scroll";

export interface SectionBecomeAnAuthorProps {
  className?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
}) => {
  return (
    <HashScroll hash="#sustainable-blockchain">
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-28" />
        <h2 className="font-semibold text-3xl sm:text-4xl xl:text-6xl mt-6 sm:mt-10 !leading-[1.112] tracking-tight">
          Sustainable Blockchain  <br />
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400 ">
          World’s first really sustainable blockchain with nodes built on ARM processors, with energy consumption as low as 1/25th of the others, proprietary Proof of Hybrid consensus.
        </span>
        {/* <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
        //   <ButtonPrimary href="/page-upload-item" className="">
        //     Create item
        //   </ButtonPrimary>
        //   <ButtonSecondary href="/page-search" className="">
        //     Discover more
        //   </ButtonSecondary>
         </div>*/}
      </div>
      <div className="flex-grow">
        <NcImage containerClassName="block dark:hidden" src={rightImgDemo} />
        <NcImage
          containerClassName="hidden dark:block"
          src={rightLargeImgDark}
        />
      </div>
    </div>
     </HashScroll>
  );
};

export default SectionBecomeAnAuthor;
