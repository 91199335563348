// import React from 'react'

// export default function Modal({ isOpen, onClose, children }) {
//   return (
//        <div className="fixed z-10 top-0 left-0 w-full h-full flex items-center justify-center">
//       <div className="absolute z-20 w-1/2 bg-white rounded-lg">
//         <div className="px-4 py-2 bg-gray-200 flex justify-between items-center">
//           <h2 className="font-bold">Instructions to upload website</h2>
//           <button onClick={onClose}>&times;</button>
//         </div>
//         <div className="px-4 py-2">{children}</div>
//       </div>
//         <div
//             className="fixed z-10 top-0 left-0 w-full h-full bg-gray-900 opacity-50"
//             onClick={onClose}
//         ></div>
//     </div>
//   )
// }

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

export default function Modal({ isOpen, onClose, children }) {
  return ReactDOM.createPortal(
    <Transition show={isOpen}>
      <Dialog
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            className="relative max-w-lg w-full bg-white rounded-lg shadow-lg transition-all transform"
          >
            <div className="absolute top-0 right-0 pt-4 pr-4">
              <button onClick={onClose}>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="p-6">{children}</div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>,
    document.body
  );
}