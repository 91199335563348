// graphqlUtils.ts
import { useMutation } from '@apollo/client';

export const useCustomMutation = (mutation: any, onCompletedCallback: (data: any) => void, onErrorCallback?: (error: any) => void) => {
  const [executeMutation, { loading }] = useMutation(mutation, {
    onCompleted: onCompletedCallback,
    onError: (error) => {
      console.error("Mutation error:", error);
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    }
  });

  return { executeMutation, loading };
};
