import Modal from "containers/PageUpdateTokens/Modal";
import React, { useRef } from "react";
import  { useState } from 'react';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { ChromePicker, ColorResult } from "react-color";
import { Link } from "react-router-dom";

interface ColorPickerProps {
  color: string;
  handleColorChange: (color: ColorResult) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  color,
  handleColorChange,
}) => {
  return (
    <div>
      <ChromePicker color={color} onChange={handleColorChange} />
      <p className="py-4">Selected Color: {color}</p>
    </div>
  );
};

export default function PageDesign() {
  const [color, setColor] = useState("#FFFFFF");
  const handleColorChange = (selectedColor: any) => {
    setColor(selectedColor.hex);
  };
  const [color1, setColor1] = useState("#FFFFFF");
  const handleColorChange1 = (selectedColor: any) => {
    setColor1(selectedColor.hex);
  };
  const [color2, setColor2] = useState("#FFFFFF");
  const handleColorChange2 = (selectedColor: any) => {
    setColor2(selectedColor.hex);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpens, setIsOpens] = useState(false);


  const handleOpenModal = () => setIsOpen(true);
  const handleOpenModal2 = () => setIsOpen2(true);
  const handleOpenModal3 = () => setIsOpen3(true);
  const handleOpenModal4 = () => setIsOpen4(true);
  const handleOpenModals = () => setIsOpens(true);
  const handleCloseModal = () => setIsOpen(false);
  const handleCloseModal2 = () => setIsOpen2(false);
  const handleCloseModal3 = () => setIsOpen3(false);
  const handleCloseModal4 = () => setIsOpen4(false);
  const handleCloseModals = () => setIsOpens(false);


  const [visible, setVisible] = useState("block");
  const [visible1, setVisible1] = useState("none");
  function visibility() {
    setVisible("none");
    setStyle("bg-cover");
    setStyle1("");
    setVisible1("block")
  }
  function visibilityon() {
    setVisible("block");
    setStyle("bg-cover w-4/5");
    setStyle1("w-1/5");
  }
  const [mainHeading, setMainHeading] = useState(" Your Web3 Website");
  const [subHeading, setSubHeading] = useState(
    "Capture your personal memory in unique way, anywhere."
  );
  const [passage, setPassage] = useState("Made with love by United Protocol");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");
  const [img, setImg] = useState<string | null>("https://cdn.logo.com/hotlink-ok/logo-social-sq.png");
  const [bgimg, setBGImg] = useState<string | null>("https://images.unsplash.com/photo-1516557070061-c3d1653fa646?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  const handleImageUpload = (event: any) => {
    const file = event.target.files?.[0];
    console.log(event.target.files[0]);
    const reader = new FileReader();
    // let value = URL.createObjectURL(file);
    // console.log(file);
    reader.onload = () => {
      setImg(reader.result as string);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleImageUpload2 = (event: any) => {
    const file = event.target.files?.[0];
    const reader = new FileReader();
    // let value = URL.createObjectURL(file);
    // console.log(file);
    reader.onload = () => {
      setBGImg(reader.result as string);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const [style, setStyle] = useState("bg-cover w-4/5");
  const [style1, setStyle1] = useState("w-1/5");


  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
 
  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const textarea = textareaRef.current;
      if (textarea) {
        const start = textarea.selectionStart || 0;
        const end = textarea.selectionEnd || 0;
        const value = textarea.value || "";
        const newValue =
          value.substring(0, start) + "\n" + value.substring(end);
        textarea.value = newValue;
        textarea.selectionStart = textarea.selectionEnd = start + 1;
      }
    }
  };
  const renderedPassage = passage.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  // const fileInputRef = useRef<HTMLInputElement>(null);
  // const [fileName, setFileName] = useState("No file chosen");

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     setFileName(file.name);
  //     // Perform further processing with the selected file if needed
  //   } else {
  //     setFileName("No file chosen");
  //   }
  // };

  // const handleBrowseClick = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }
  // };
  
  return (
    <div className="w-full">
      <div
        className={style}
        style={{
          backgroundImage:
            bgimg ? `url(${bgimg})` : "none"
          // "url(https://images.unsplash.com/photo-1516557070061-c3d1653fa646?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
        }}
      >

        <button style={{ display: visible1 }}
          onClick={visibilityon}
          className="bg-[#0284c7] mx-3 cursor-pointer text-white font-bold py-2 px-4 rounded-3xl absolute top-5 right-0"
        >
          Edit
        </button>
        <button 
          className="bg-[#0284c7] mx-3 cursor-pointer text-white font-bold py-2 px-4 rounded-3xl absolute top-5 left-0"
        ><Link to="/edit-domain-data">
          Back</Link>
        </button>
        {/* <div className="absolute bg-black opacity-60 inset-0 z-0"></div> */}
        <div className="min-h-screen  text-white">
          <div className="py-10 flex flex-col justify-center items-center container">
            {img && (
              <img
                style={{ width: "8%" }}
                src={img}
                alt="your logo will display here"
              />
            )}

            <h1
              className={`py-2 text-5xl font-bold tracking-wide `}
              style={{ color: color }}
            >
              {mainHeading}
            </h1>
            <p className="text-3xl my-4" style={{ color: color1 }}>
              {subHeading}
            </p>
            <p className="text-xl my-4" style={{ color: color2 }}>
            {renderedPassage}
            </p>
          </div>

          <div className="py-10 flex justify-center">
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">
              <div className="mt-1.5 flex">
                <span className="inline-flex items-center px-3 rounded-l-2xl  bg-neutral-50  text-neutral-500  w-36 text-sm">
                  Website
                </span>
                <input
                  className="h-11 w-full px-4 py-3 text-sm font-normal rounded-2xl !rounded-l-none"
                  name="website"
                  disabled
                  placeholder="yourwebsite.com"
                />
              </div>

              <div className="mt-1.5 flex text-neutral-700 dark:text-neutral-300">
                <span className="inline-flex items-center px-3 rounded-l-2xl  bg-neutral-50  text-neutral-500  w-36 text-sm">
                  NFT ID
                </span>
                <input
                  className="h-11 w-full px-4 py-3 text-sm font-normal rounded-2xl !rounded-l-none"
                  name="nftid"
                  disabled
                  placeholder="nft id"
                />
              </div>

              <div>
                <div className="mt-1.5 flex text-neutral-700 dark:text-neutral-300">
                  <span className="inline-flex items-center px-3 rounded-l-2xl  bg-neutral-50  text-neutral-500  w-36 text-sm">
                    Wallet Address
                  </span>

                  <input
                    className="h-11 w-full px-4 py-3 text-sm font-normal rounded-2xl !rounded-l-none"
                    name="walletAddress"
                    disabled
                    placeholder="wallet address"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-0  p-4 text-center right-0 left-0 flex justify-center space-x-4">
            <span>
              <svg
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
              </svg>
            </span>
            <span>
              <svg
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
              </svg>
            </span>
            <span>
              <svg
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"></path>
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div className={style1} style={{ display: visible }}>
        <aside
          id="default-sidebar"
          className="absolute top-0 right-0 z-1 h-screen "
          aria-label="Sidebar"
        >
          <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
            <p className="font-bold">Edit Content</p>
            <form encType="multipart/form-data" method="post" target="_blank" action="http://localhost:5000/uploadLogo">
              <input type="hidden" value={decodeURI(window.location.search).split("?")[1].split("=")[1]} name="ipfsToken" />
              <input type="hidden" value={mainHeading} name="heading1" />
              <input type="hidden" value={color} name="heading1_color"></input>
              <input type="hidden" name="heading2" value={subHeading} />
              <input type="hidden" value={color1} name="heading2_color"></input>
              <input type="hidden" value={passage} name="description" />
              <input type="hidden" value={color2} name="description_color"></input>
              <input type="hidden" value={link1} name="link_1" />
              <input type="hidden" value={link2} name="link_2" />
              <input type="hidden" value={link3} name="link_3" />
              

              <ul className="space-y-2 font-medium mt-2">
                <li>
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <span
                      onClick={handleOpenModal2}
                      className="ml-3 cursor-pointer"
                    >
                      Main Heading
                    </span>
                  </div>
                </li>
                <Modal isOpen={isOpen2} onClose={handleCloseModal2}>
                  <div>
                    <h1 className="py-4 font-bold text-xl">Main Heading</h1>
                    <h1 className="py-4 ">Text</h1>
                    <Input className=" border-2 border-gray-600"
                      onChange={(event) => {
                        setMainHeading(event.target.value);
                      }}
                    ></Input>
                  </div>
                  <h1 className="py-4 ">Text Color</h1>
                  <div className="flex justify-center">
                    <ColorPicker
                      color={color}
                      handleColorChange={handleColorChange}
                    />
                  </div>
                </Modal>

                <li>
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <span
                      onClick={handleOpenModal3}
                      className="ml-3 cursor-pointer"
                    >
                      Sub Heading
                    </span>
                  </div>
                </li>
                <Modal isOpen={isOpen3} onClose={handleCloseModal3}>
                  <div>
                    <h1 className="py-4 font-bold text-xl">Sub Heading</h1>
                    <h1 className="py-4 ">Text</h1>
                    <Input className=" border-2 border-gray-600"
                      onChange={(event) => {
                        setSubHeading(event.target.value);
                      }}
                    ></Input>
                  </div>
                  <h1 className="py-4 ">Text Color</h1>
                  <div className="flex justify-center">
                    <ColorPicker
                      color={color1}
                      handleColorChange={handleColorChange1}
                    />
                  </div>
                </Modal>
                <li>
                  {" "}
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <span
                      onClick={handleOpenModals}
                      className="ml-3 cursor-pointer"
                    >
                      Description
                    </span>
                  </div>
                </li>
                <Modal isOpen={isOpens} onClose={handleCloseModals}>
                  <div>
                    <h1 className="py-4 font-bold text-xl">Description</h1>
                    <h1 className="py-4 ">Text</h1>

                    <textarea  onKeyDown={handleKeyPress}  ref={textareaRef}
                      className="w-full rounded-xl h-48 resize-none border-2 border-gray-600"
                      onChange={(event) => {
                        setPassage(event.target.value);
                      }}
                    ></textarea>
                  </div>
                  <h1 className="py-4 ">Text Color</h1>
                  <div className="flex justify-center">
                    <ColorPicker
                      color={color2}
                      handleColorChange={handleColorChange2}
                    />
                  </div>
                </Modal>
                <li>
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <span
                      onClick={handleOpenModal4}
                      className="ml-3 cursor-pointer "
                    >
                      Personal Links
                    </span>
                  </div>
                </li>
                <Modal isOpen={isOpen4} onClose={handleCloseModal4}>
                  <div>
                    <h1 className="py-4 font-bold text-xl">Links</h1>
                    <span className="py-3">Your Twitter Link</span>
                    <Input onChange={(event: any) => { setLink1(event.target.value) }} className=" border-2 border-gray-600" value={link1}></Input>
                    <span className="py-3">Your Facebook Link</span>

                    <Input onChange={(event: any) => { setLink2(event.target.value) }} className=" border-2 border-gray-600" value={link2}></Input>
                    <span className="py-3">Your Instagram Link</span>

                    <Input onChange={(event: any) => { setLink3(event.target.value) }} className=" border-2 border-gray-600" value={link3}></Input>
                  </div>
                </Modal>

                {/* <li>
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <span className="ml-3 cursor-pointer">Your Instagram Link</span>
                  </div>
                </li>
                <li>
                  <div>
                    <Input></Input>
                  </div>
                </li>
                <li></li>
                <li>
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <span className="ml-3 cursor-pointer">Your Facebook Link</span>
                  </div>
                </li>
                <li>
                  <div>
                    <Input></Input>
                  </div>
                </li> */}

                {/* <li>
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <span
                      onClick={handleOpenModalss}
                      className="ml-3 cursor-pointer"
                    >
                      Text Color
                    </span>
                  </div>
                </li>
                <Modal isOpen={isOpenss} onClose={handleCloseModalss}>
                  <div className="flex justify-center">
                    <ColorPicker
                      color={color}
                      handleColorChange={handleColorChange}
                    />
                  </div>
                </Modal> */}
                <li>
                <div>
                <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <span
                      className="ml-3 cursor-pointer"
                    >
                Upload Logo
                    </span>
                  </div>
                <div className="border-2 border-solid  px-1 py-8 rounded-xl">
                  <Input
                    type="file"
                    onChange={(event:any)=>{
                      handleImageUpload(event);
                      // event.target.value.name=decodeURI(window.location.search).split("?")[1].split("=")[1]+"_logo";
                      console.log(event.target.value.name);
                    }}
                    accept="image/*"
                    name="logo"
                  />
                </div>
              </div>
              </li>
              <li>
              <div>
              <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <span
                      className="ml-3 cursor-pointer"
                    >
                Upload Background Image
                    </span>
                  </div>
                <div className="border-2 border-solid px-1 py-8 rounded-xl">
                  <Input
                    type="file"
                    onChange={(event:any)=>{
                      handleImageUpload2(event);
                      // event.target.value.name=decodeURI(window.location.search).split("?")[1].split("=")[1]+"_background";
                    }}
                    accept="image/*"
                    name="background"
                  />
                </div>
              </div>
              </li>
              {/* <div>
      <input
        type="file"
        id="fileInput"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <label htmlFor="fileInput" onClick={handleBrowseClick}>
        {fileName}
      </label>
    </div> */}
                {/* <li>
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                    <span
                      onClick={handleOpenModal}
                      className="ml-3 cursor-pointer"
                    >
                      Logo and Background Images
                    </span>
                  </div>
                </li> */}
                <Modal isOpen={isOpen} onClose={handleCloseModal}>

                </Modal>

                <li>
                  <a  
                    onClick={visibility}
                    className="bg-[#0284c7] ml-3 my-3 cursor-pointer text-white font-bold py-2 px-4 rounded-3xl"
                  >
                    Preview
                  </a>
                  <button
                    className="bg-[#0284c7] ml-3 my-3 text-white font-bold py-2 px-4 rounded-3xl"
                    type="submit"
                  >
                    Submit
                  </button>
                </li>
                <li></li>
              </ul>
            </form>
          </div>
        </aside>
      </div>
    </div>
  );
}
