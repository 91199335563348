import React from "react";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import { Helmet } from "react-helmet";
import SectionLargeSlider from "./SectionLargeSlider";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionHero2 from "components/SectionHero/SectionHero2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionGridFeatureNFT2 from "./SectionGridFeatureNFT2";
import SectionMagazine8 from "components/SectionMagazine8";
import SectionSliderCardNftVideo from "components/SectionSliderCardNftVideo";
import SectionSliderCollections2 from "components/SectionSliderCollections2";
import Faq from "components/Faq";
import Cookie from "components/Cookie";
import Heading from "components/Heading/Heading";
import { HashScroll } from "react-hash-scroll";
import { Link } from "react-router-dom";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>United Protocol - A Web3
          Believers Ecosystem</title>
      </Helmet>
      {/* <Cookie/> */}
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* <Cookie /> */}

      <div
        className="container relative  mb-20 sm:mb-24 lg:mt-10 lg:mb-32"
        id="BuyDomains"
      >
        <div className="text-center mb-20 ">
          <hr className="mt-5"></hr>
          <h1 className="text-4xl mb-2 pt-5">

            United Protocol is here to build {" "}
            <span className="font-bold">
              A Web3
              Believers Ecosystem.
            </span>
          </h1>
          <span className="text-2xl  text-[#0ac74c]">Decentralized</span>
          <span className="text-2xl  ">   | Humanized</span>
          <hr className="mt-5"></hr>
          {/* <div className="p-2"></div> */}
        </div>
        {/* SECTION HERO */}
        <SectionHero2 />

        {/*Info Card*/}
        <h1 className="border-2 rounded-xl border-purple-500 p-5 mt-32 text-gray-900 dark:text-neutral-50 text-2xl text-center">
          Create a <b>Top-Level Domain</b> for your community with United Protocol
        </h1>
        <div className=" max-w-sm w-full lg:max-w-full lg:flex container mt-24 lg:mt-40 xl:mt-48 text-center">
          <div className="dark:bg-slate-900 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
            <div className="mb-8">
              <div className="dark:text-neutral-50 text-gray-900 font-bold text-5xl mb-2">
                It all starts with a name.
              </div>
              <p className="text-gray-700 dark:text-neutral-50 text-xl mt-10">
                United Protocol domains are NFT domains designed to help you build your Web3 identity, which can be used to resolve websites (W3NS), provide decentralized single secure login (DSSL), and offer a decentralized email service (DES).
                If you missed the opportunity to be a part of Web1 by not acquiring a .com domain, don't make the same mistake with Web3. Secure your place in the future of the internet with United Protocol domains."
              </p>
              <p className="mt-8 text-gray-700 dark:text-neutral-50 text-xl">
                You have missed out on being part of Web1 by missing .com, don’t
                miss out on being part of Web3 with United Protocol Domains.
              </p>

            </div>

            <div className="px-6 pt-4 pb-2">
              <span className="inline-block rounded-full px-3 py-1 font-semibold mr-2 mb-2">
                BOOK
              </span>
              <br />
              <span className="inline-block text-xl bg-gray-200 rounded-full px-5 py-2 font-semibold text-gray-700 mr-2 mb-2">
                .upu
              </span>
              {/* <span className="inline-block bg-gray-200 rounded-full px-3 py-1 font-semibold text-gray-700 mr-2 mb-2">
                .W3
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 font-semibold text-gray-700 mr-2 mb-2">
                .VERSE
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 font-semibold text-gray-700 mr-2 mb-2">
                .TOKEN
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 font-semibold text-gray-700 mr-2 mb-2">
                .DEFI
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 font-semibold text-gray-700 mr-2 mb-2">
                .DAPP
              </span> */}
              <br />
              <span className="inline-block rounded-full px-3 py-1  text-xl mr-2 mb-2">
                domains names
              </span>
            </div>
          </div>
        </div>
        <h1 className="border-2 rounded-xl border-purple-500 p-5 mt-32 text-gray-900 dark:text-neutral-50 text-2xl text-center">
          United Protocol domains are best experienced on <b>DamGo Web3 Browser.</b>
        </h1>
        <div className="container relative space-y-24 my-12  lg:space-y-16 lg:my-16">
          <BackgroundSection />
          <SectionLargeSlider className="py-24" />
        </div>
        {/* <SectionHowItWork className="mt-24 lg:mt-40 xl:mt-48" /> */}
        <div className="text-center text-2xl border-2 rounded-xl p-5 border-[#0ac74c]"> United Protocol Domains and DamGo Web3 Browser are powered by the <Link to="/thenextinternet" className="text-[#0ac74c]">United Protocol Layer 1 Blockchain</Link></div>
      </div>

      <Heading
        className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
        fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
        isCenter
        desc=""
      >
        Benefits
      </Heading>
      {/* SECTION LAERGE SLIDER */}
      <HashScroll hash="benefits">
        <div className="flex flex-col lg:flex-row text-center  overflow-hidden   container">
          <div className="px-6 py-4 mx-1">
            <div className="font-bold text-xl mb-2">OWN IT</div>
            <p className="text-gray-700 text-base dark:text-neutral-50 ">
            Pay once for your United names and own it for a lifetime. No more renting.
            </p>
          </div>
          <div className="px-6 py-4 mx-1">
            <div className="font-bold text-xl mb-2">WEB3 WEBSITE</div>
            <p className="text-gray-700 text-base dark:text-neutral-50">
              Build your Web3 website with no hosting fees for a lifetime.

            </p>
          </div>
          <div className="px-6 py-4 mx-1">
            <div className="font-bold text-xl mb-2">
              NO MORE EXTENSION OR DNS SETTINGS{" "}
            </div>
            {/* <div className="font-bold text-xl mb-2">COMING SOON</div> */}
            <p className="text-gray-700 text-base dark:text-neutral-50">
              DamGo Web3 Browser resolves all United Protocol domains automatically.

            </p>
          </div>
          <div className="px-6 py-4 mx-1">
            <div className="font-bold text-xl mb-2">
              NO MORE @LPHANUMERIC CODES
            </div>
            <div className="font-bold text-xl mb-2">COMING SOON</div>
            <p className="text-gray-700 text-base dark:text-neutral-50">
              Receive cryptocurrency payments with your personalized domain names."

            </p>
          </div>
        </div>
      </HashScroll>

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION 3 */}
        {/* <SectionMagazine8 /> */}

        {/* SECTION */}
        {/* <div className="relative py-20 lg:py-28 ">
          <BackgroundSection />
          <SectionGridAuthorBox
            sectionStyle="style2"
            data={Array.from("11111111")}
            boxCard="box4"
          />
        </div> */}

        <Faq />

        {/* SECTION 4 */}
        {/* <SectionSliderCardNftVideo /> */}

        {/* SECTION */}
        {/* <div className="relative py-20 lg:py-28">
          <BackgroundSection />
          <SectionSliderCollections2 cardStyle="style2" />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}

        {/* SECTION */}
        {/* <div className="relative py-20 lg:py-28">
          <BackgroundSection className="bg-neutral-100/70 dark:bg-black/20 " />
          <SectionGridFeatureNFT2 />
        </div> */}

        {/* SECTION 1 */}
        {/* <SectionSliderCategories /> */}

        {/* SECTION */}
        {/* <div className="relative py-20 lg:py-24">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}

        {/* SECTION */}
        <SectionVideos />
      </div>
    </div>
  );
}

export default PageHome;
