import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function PageDocumentation() {
useEffect(()=>{
window.location.replace("https://docs.unitedprotocol.org/");
},[])
//   const toggleButtons = document.querySelectorAll("[data-collapse-toggle]");


//   toggleButtons.forEach((button) => {
//     button.addEventListener("click", () => {
   
//       const targetId = button.getAttribute("data-collapse-toggle");
//       const targetMenu = document.getElementById(targetId);

//       targetMenu.classList.toggle("hidden");
//     });
//   });

//   const [vis1,setVis1]= useState("block")
//   const [vis2,setVis2]= useState("none")
//   const [vis3,setVis3]= useState("none")
//   const [vis4,setVis4]= useState("none")
//   const [vis5,setVis5]= useState("none")
//   const [vis6,setVis6]= useState("none")
//   const [vis7,setVis7]= useState("none")
//   const [vis8,setVis8]= useState("none")
//   const [vis9,setVis9]= useState("none")

//   const code1 = `const { HOW3Protocol } = require("");
//   \n async function check() 
//   \n {console.log(await HOW3Protocol.isAvailable("vitalik.eth"));} `

//   function visi1(){
//     setVis1("block");
//     setVis2("none");
//     setVis3("none");
//     setVis4("none");
//     setVis5("none");
//     setVis6("none");
//     setVis7("none");
//     setVis8("none");
//     setVis9("none");
//   }
//   function visi2(){
//     setVis1("none");
//     setVis2("blocl");
//     setVis3("none");
//     setVis4("none");
//     setVis5("none");
//     setVis6("none");
//     setVis7("none");
//     setVis8("none");
//     setVis9("none");
//   }
//   function visi3(){
//     setVis1("none");
//     setVis2("none");
//     setVis3("block");
//     setVis4("none");
//     setVis5("none");
//     setVis6("none");
//     setVis7("none");
//     setVis8("none");
//     setVis9("none");
//   }
//   function visi4(){
//     setVis1("none");
//     setVis2("none");
//     setVis3("none");
//     setVis4("block");
//     setVis5("none");
//     setVis6("none");
//     setVis7("none");
//     setVis8("none");
//     setVis9("none");
//   }
//   function visi5(){
//     setVis1("none");
//     setVis2("none");
//     setVis3("none");
//     setVis4("none");
//     setVis5("block");
//     setVis6("none");
//     setVis7("none");
//     setVis8("none");
//     setVis9("none");
//   }
//   function visi6(){
//     setVis1("none");
//     setVis2("none");
//     setVis3("none");
//     setVis4("none");
//     setVis5("none");
//     setVis6("block");
//     setVis7("none");
//     setVis8("none");
//     setVis9("none");
//   }
//   function visi7(){
//     setVis1("none");
//     setVis2("none");
//     setVis3("none");
//     setVis4("none");
//     setVis5("none");
//     setVis6("none");
//     setVis7("block");
//     setVis8("none");
//     setVis9("none");
//   }
//   function visi8(){
//     setVis1("none");
//     setVis2("none");
//     setVis3("none");
//     setVis4("none");
//     setVis5("none");
//     setVis6("none");
//     setVis7("none");
//     setVis8("block");
//     setVis9("none");
//   }
//   function visi9(){
//     setVis1("none");
//     setVis2("none");
//     setVis3("none");
//     setVis4("none");
//     setVis5("none");
//     setVis6("none");
//     setVis7("none");
//     setVis8("none");
//     setVis9("block");
//   }

  return (
    <Link to="https://docs.unitedprotocol.org/"></Link>
//     <div>
//       <hr></hr>
//       <button
//         data-drawer-target="sidebar-multi-level-sidebar"
//         data-drawer-toggle="sidebar-multi-level-sidebar"
//         aria-controls="sidebar-multi-level-sidebar"
//         type="button"
//         className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
//       >
//         <span className="sr-only">Open sidebar</span>
//         <svg
//           className="w-6 h-6"
//           aria-hidden="true"
//           fill="currentColor"
//           viewBox="0 0 20 20"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             clip-rule="evenodd"
//             fill-rule="evenodd"
//             d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
//           ></path>
//         </svg>
//       </button>

//       <aside
//         id="sidebar-multi-level-sidebar"
//         className="absolute top-30 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
//         aria-label="Sidebar"
//       >
//         <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
//           <ul className="space-y-2 font-medium">
//             <li>
//               <a
//                onclick={visi1}
//                 className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
//               >
//                 <svg
//                   aria-hidden="true"
//                   className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
//                   <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
//                 </svg>
//                 <span className="ml-3">Introduction</span>
//               </a>
//             </li>
//             <li>
//               <a
//               onclick={visi2}
//                 className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
//               >
//                 <svg
//                   aria-hidden="true"
//                   className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
//                   <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
//                 </svg>
//                 <span className="flex-1 ml-3 whitespace-nowrap">Install</span>
               
//               </a>
//             </li>
//             <li>
//               <a
//              onclick={visi3}
//                 className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
//               >
//                 <svg
//                   aria-hidden="true"
//                   className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
//                 </svg>
//                 <span className="flex-1 ml-3 whitespace-nowrap">
//                   How it works
//                 </span>
               
//               </a>
//             </li>
//             <li>
//               <button
//                 type="button"
//                 className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
//                 aria-controls="dropdown-example"
//                 data-collapse-toggle="dropdown-example"
//               >
//                 <svg
//                   aria-hidden="true"
//                   className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     fill-rule="evenodd"
//                     d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
//                     clip-rule="evenodd"
//                   ></path>
//                 </svg>
//                 <span
//                   className="flex-1 ml-3 text-left whitespace-nowrap"
//                   sidebar-toggle-item
//                 >
//                   Code Example
//                 </span>
//                 <svg
//                   sidebar-toggle-item
//                   className="w-6 h-6"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     fill-rule="evenodd"
//                     d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
//                     clip-rule="evenodd"
//                   ></path>
//                 </svg>
//               </button>
//               <ul id="dropdown-example" className="hidden py-2 space-y-2">
//                 <li>
//                   <a
//                     onclick={visi4}
//                     className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
//                   >
//                     Search
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     onclick={visi5}
//                     className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
//                   >
//                     Register
//                   </a>
//                 </li>
//               </ul>
//             </li>
//             <li>
//               <button
//                 type="button"
//                 className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
//                 aria-controls="dropdown-example"
//                 data-collapse-toggle="dropdown-example"
//               >
//                 <svg
//                   aria-hidden="true"
//                   className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     fill-rule="evenodd"
//                     d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
//                     clip-rule="evenodd"
//                   ></path>
//                 </svg>
//                 <span
//                   className="flex-1 ml-3 text-left whitespace-nowrap"
//                   sidebar-toggle-item
//                 >
//                   Function
//                 </span>
//                 <svg
//                   sidebar-toggle-item
//                   className="w-6 h-6"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     fill-rule="evenodd"
//                     d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
//                     clip-rule="evenodd"
//                   ></path>
//                 </svg>
//               </button>
//               <ul id="dropdown-example" className="hidden py-2 space-y-2">
//                 <li>
//                   <a
//                     onclick={visi6}
//                     className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
//                   >
//                     Add your domain service
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     onclick={visi7}
//                     className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
//                   >
//                     Transfer using Hardhat
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     onclick={visi8}
//                     className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
//                   >
//                     Transfer using Metamask
//                   </a>
//                 </li>
//               </ul>
//             </li>

//             <li>
//               <a
//                 onclick={visi9}
//                 className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
//               >
//                 <svg
//                   aria-hidden="true"
//                   className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     fill-rule="evenodd"
//                     d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
//                     clip-rule="evenodd"
//                   ></path>
//                 </svg>
//                 <span className="flex-1 ml-3 whitespace-nowrap">
//                   Verify your certificate
//                 </span>
//               </a>
//             </li>
//           </ul>
//         </div>
//       </aside>
//       <div style={{display:vis1}} className="p-4 sm:ml-64 min-h-screen">
//       <p>This code search for the availability of the domain name and TLD and determines the owner of the unavailable domain name or TLD.</p>
// <p>Input param:</p>
// <p>Domain name - [String] example - “vitalik.eth”</p>
// <div>
//   	{/* const { HOW3Protocol } = require(""); \n async function check()\n {console.log(await HOW3Protocol.isAvailable("vitalik.eth"));} */}
// {code1}
// </div>
//       </div>
//       <div style={{display:vis2}} className="p-4 sm:ml-64 min-h-screen">Body2</div>
//       <div style={{display:vis3}} className="p-4 sm:ml-64 min-h-screen">Body3</div>
//       <div style={{display:vis4}} className="p-4 sm:ml-64 min-h-screen">
//  Body4
// </div>
//       <div style={{display:vis5}} className="p-4 sm:ml-64 min-h-screen">Body5</div>
//       <div style={{display:vis6}} className="p-4 sm:ml-64 min-h-screen">Body6</div>
//       <div style={{display:vis7}} className="p-4 sm:ml-64 min-h-screen">Body7</div>
//       <div style={{display:vis8}} className="p-4 sm:ml-64 min-h-screen">Body8</div>
//       <div style={{display:vis9}} className="p-4 sm:ml-64 min-h-screen">Body9</div>
//     </div>
  )
  }
