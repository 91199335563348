import React, { FC, useEffect, useId, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CollectionCard from "./CollectionCard";
import CollectionCard2 from "./CollectionCard2";
import { Link } from "react-router-dom";
import pattern from "../images/Website creative/400x400.jpg";

export interface SectionSliderCollectionsProps {
  className?: string;
  itemClassName?: string;
  cardStyle?: "style1" | "style2";
}

const SectionSliderCollections: FC<SectionSliderCollectionsProps> = ({
  className = "",
  cardStyle = "style1",
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.5,
        },
        1024: {
          gap: 20,
          perView: 2.15,
        },
        768: {
          gap: 20,
          perView: 1.5,
        },

        500: {
          gap: 20,
          perView: 1,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS]);

  const MyCollectionCard =
    cardStyle === "style1" ? CollectionCard : CollectionCard2;

  return (
    <div className={`nc-SectionSliderCollections ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          isCenter={false}
          // hasNextPrev  
        >
          And it will be
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            <li className={`glide__slide`}>
              <MyCollectionCard
                imgs={[
                  pattern,
                  "https://images.unsplash.com/photo-1599054802207-91d346adc120?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
                  "https://images.unsplash.com/photo-1581985673473-0784a7a44e39?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDl8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
                  "https://images.unsplash.com/photo-1557264305-7e2764da873b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE3fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                ]}
                name ={"Sustainable (UN SDGs)"}
              />
            </li>
            <li className={`glide__slide`}>
              <MyCollectionCard
                imgs={[
                  pattern,
                  "https://images.unsplash.com/photo-1617791160505-6f00504e3519?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDl8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
                  "https://images.unsplash.com/photo-1637611331620-51149c7ceb94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDExfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                  "https://images.unsplash.com/photo-1618172193622-ae2d025f4032?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE2fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                ]}
                name ={"Decentralized (Secure power to us)"}
              />
            </li>
            <li className={`glide__slide`}>
              <MyCollectionCard
                imgs={[
                  pattern,
                  "https://images.unsplash.com/photo-1617791160588-241658c0f566?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
                  "https://images.unsplash.com/photo-1626544827763-d516dce335e2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEzfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                  "https://images.unsplash.com/photo-1627037558426-c2d07beda3af?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE4fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                ]}
                name ={"Personalized (Everyone is unique)"}
              />
            </li>
            {/*<li className={`glide__slide   `}>
            //   <Link to={"/page-search"} className="block relative group">
            //     <div className="relative rounded-2xl overflow-hidden h-[410px]">
            //       <div className="h-[410px] bg-black/5 dark:bg-neutral-800"></div>
            //       <div className="absolute inset-y-6 inset-x-10  flex flex-col items-center justify-center">
            //         <div className="flex items-center justify-center relative">
            //           <span className="text-xl font-semibold">Collections</span>
            //           <svg
            //             className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
            //             viewBox="0 0 24 24"
            //             fill="none"
            //             xmlns="http://www.w3.org/2000/svg"
            //           >
            //             <path
            //               d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
            //               stroke="currentColor"
            //               strokeWidth="1.5"
            //               strokeMiterlimit="10"
            //               strokeLinecap="round"
            //               strokeLinejoin="round"
            //             />
            //             <path
            //               d="M12 20.4999V3.66992"
            //               stroke="currentColor"
            //               strokeWidth="1.5"
            //               strokeMiterlimit="10"
            //               strokeLinecap="round"
            //               strokeLinejoin="round"
            //             />
            //           </svg>
            //         </div>
            //         <span className="text-sm mt-1">Show me more</span>
            //       </div>
            //     </div>
            //   </Link>
             </li>*/}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderCollections;
