import React,{useEffect,useState} from "react";
import { useLocation } from "react-router-dom";
import HeaderLogged from "components/Header/HeaderLogged";
import Header2 from "components/Header/Header2";
import { UserGlobalContext } from "user";
const SiteHeader = () => {
  let location = useLocation();
    const { LoggedIn } = UserGlobalContext();
    if(window.location.pathname!=="/design"){
    if(LoggedIn===false){
    return <Header2 />
  }
  else{
    return <HeaderLogged/>
  }
}
else {
  return null;
}
};

export default SiteHeader;
