import React, { useState } from "react";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import loading from "../images/loading.jpg";

export default function PageForgeotpassword() {
  const [verifyLoading2, setVerifyLoading2] = useState("none");
  const [email, setEmail] = useState("");
  async function onSubmit() {
    setVerifyLoading2("block");
   await axios
      .post("http://localhost:5000/forgotpass", { username: email })
      .then(function (response)
      {
        setVerifyLoading2("none"); 
        console.log(response);
        alert(response.data);
      })
      .catch((err) => {
        setVerifyLoading2("none"); 
        alert(err);
      });
  }
  return (
    <div className="max-w-md mx-auto my-24">
      {/* FORM */}
      <div className="grid grid-cols-1 gap-6">
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">Email</span>
          <Input
            name="username"
            onChange={(event) => setEmail(event.target.value)}
            type="email"
            placeholder="Email"
            className="mt-1"
          />
        </label>
        <ButtonPrimary onClick={onSubmit}>Send Mail</ButtonPrimary>
        <img
          src={loading}
          style={{ display: verifyLoading2 }}
          className=" w-6 h-6"
        ></img>
      </div>
    </div>
  );
}
