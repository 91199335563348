import React from "react";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import Vector1 from "images/Vector1.png";
import SectionLargeSlider from "./SectionLargeSlider";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionGridFeatureNFT from "./SectionGridFeatureNFT";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Next Internet || Sustainable Blockchain</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-20 mt-12 mb-20 sm:space-y-24 sm:my-24 lg:space-y-32 lg:my-32">
        {/* SECTION HERO */}
        <SectionHero
          className=""
          heading={
            <span>
              United Protocol
              <br />
              is here to build <br /> THE NEXT INTERNET.
              <br /> {` `}
              <span className="relative pr-3">
                <img
                  className="w-full absolute bottom-3 -left-1"
                  src={Vector1}
                  alt="Vector1"
                />
                {/* <span className="relative">And it will be</span> */}
              </span>
            </span>
          }
        />

        {/* SECTION 2 */}
        {/*<SectionHowItWork />
      </div>
      <div className="bg-neutral-100/80 dark:bg-black/20 py-20 lg:py-32">
        <div className="container">
          <SectionLargeSlider />
        </div>
      </div>

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        <SectionGridAuthorBox boxCard="box3" />*/}

        {/* SECTION */}
        <div className="relative py-4">
          {/* <BackgroundSection /> */}
          <SectionSliderCollections />  
        </div>
        <div>
          <h1 className="text-xl">
            This version of the internet requires new computing experiences like
            Decentralized Domains, Web3 Browser, DSSL and many more. With
            research and ux based culture, we work on experiences that are
            designed for humans.
          </h1>
          <br></br> 
          <h1 className="text-xl">
            Our major focus is to build all these experiences as sustainable and
            become the <b>Official carer of Mother Nature.</b>
          </h1>
          <br></br>
          <h1 className="text-xl font-bold">
            All of our products are powered by United Protocol Sustainable Blockchain.{" "}
          </h1>
        </div>
        {/* SECTION */}
        {/* <SectionGridFeatureNFT />*/}

        {/* SECTION */}
        <div className="relative py-20 lg:py-24" id="Blockchain">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

        {/* SECTION */}
        {/*<SectionSubscribe2 />*/}

        {/* SECTION 1 */}
        {/*<SectionSliderCategories />*/}

        {/* SECTION */}
        {/* <SectionVideos />*/}
        <h1 className="text-4xl text-bold text-center">Comparison</h1>
        <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
        <table className="min-w-full">
          <thead className="bg-gray-200 border-b dark:bg-neutral-800">
            <tr>
              <th
                scope="col"
                className="dark:text-neutral-50 text-sm font-medium text-gray-900 px-6 text-left"
              ></th>
              <th
                scope="col"
                className="dark:text-neutral-50 text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Ethereum
              </th>
              <th
                scope="col"
                className="dark:text-neutral-50 text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Bitcoin
              </th>
              <th
                scope="col"
                className="dark:text-neutral-50 text-sm font-medium text-gray-900 px-12 py-4 text-left"
              >
                United Protocol
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
              <td className="dark:text-neutral-50 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Energy Efficiency
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                NO- GPU Optimal
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                No-ASIC Optimal
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full">
                  <p className="text-sm leading-5 text-green-700">
                    Yes - ARM Optimal
                  </p>
                </div>
              </td>
            </tr>
            <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
              <td className="dark:text-neutral-50 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Safety Threshold
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                34%
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                51%
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full">
                  <p className="text-sm leading-5 text-green-700">33%</p>
                </div>
              </td>
            </tr>
            <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
              <td className="dark:text-neutral-50 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                TPS
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                100k
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                7
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full">
                  <p className="text-sm leading-5 text-green-700">1500</p>
                </div>
              </td>
            </tr>
            <tr className="bg-white  dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
              <td className="dark:text-neutral-50 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Sybil Protection
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                Proof of Stake
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                Proof of Work
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full">
                  <p className="text-sm leading-5 text-green-700">
                    Proof of Hybrid
                  </p>
                </div>
              </td>
            </tr>
            <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
              <td className="dark:text-neutral-50 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                SDGs
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                No
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                No
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full">
                  <p className="text-sm leading-5 text-green-700">At Core</p>
                </div>
              </td>
            </tr>
            <tr className="bg-white dark:bg-neutral-800 border-b transition duration-300 ease-in-out hover:bg-gray-100">
              <td className="dark:text-neutral-50 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Transactional finality
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                15 min
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                60 min
              </td>
              <td className="dark:text-neutral-50 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="w-32 flex items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full">
                  <p className="text-sm leading-5 text-green-700">6 sec</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  );
}

export default PageHome;
