import react, { useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import Mydomain from "containers/AuthorPage/mydomain";
import Axios from "axios";


const domains1 = [
  { name: "example.com", cost: 10 },
  { name: "mywebsite.net", cost: 15 },
  { name: "cooldomain.org", cost: 8 },
  { name: ".io", cost: 25 },
  { name: ".co", cost: 30 },
  { name: ".biz", cost: 35 },
];

const TableLayout = ({ data, title }: { data: any[]; title: string }) => {
  return (
    <div className="mb-8 w-full md:w-3/4 mx-auto">
      <h2 className="text-lg font-bold mb-4">{title}</h2>
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left">Index</th>
            <th className="px-6 py-3 text-left">Domain</th>
            <th className="px-6 py-3 text-left">Price</th>
            <th className="px-6 py-3 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((domain: any, index: any) => {
            return (
              <Mydomain
                key={index}
                index={index + 1}
                domain={domain.name}
                price={domain.cost}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
function Domains() {
  const [domains, setDomains] = useState(domains1);
  const [flag, setFlag] = useState(false);

  const normalDomains = domains.filter(
    (domain: any) => !domain.name.startsWith(".")
  );
  const tlds = domains.filter((domain) => domain.name.startsWith("."));

  // useEffect(() => {
  //   console.log("ncdncv");
  //   Axios.get("http://localhost:5000/getdomains", {
  //     withCredentials: true,
  //   }).then((response) => {
  //     setDomains(response.data);
  //     console.log("getDomains", response.data[0]);
  //     setFlag(true);
  //   });
  // }, [flag]);
  if (domains.length != 0) {
    return (
      <div>
      {/* Render the table for normal domains */}
      <TableLayout data={normalDomains} title="Domains" />

      {/* Render the table for TLDs */}
      <TableLayout data={tlds} title="TLDs" />
    </div>
    );
  } else {
    return (
      //      <tr className=" dark:bg-neutral-800 bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
      //        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-neutral-50">
      //          #
      //        </td>
      //        <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
      //          Nothing to show
      //        </td>
      //        <td className="text-sm dark:text-neutral-50 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
      //          Buy your web3 domain now!!
      //        </td>
      //        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
      //  <ButtonPrimary>Buy Domains<Link to={"/"} className="absolute inset-0"></Link></ButtonPrimary>
      //  </td>
      //      </tr>
      <div className="text-center">
        <h1 className="text-3xl my-4 font-bold text-center">Not Available</h1>
        <h2 className="text-3xl my-4 font-bold text-center">
          Buy your web3 domain, Now!
        </h2>
        <ButtonPrimary>
          Buy Now<Link to={"/"} className="absolute inset-0"></Link>
        </ButtonPrimary>
      </div>
    );
  }
}
export default Domains;
