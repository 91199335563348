import { ApolloProvider } from "@apollo/client";
import React from "react";
import MyRouter from "routers/index";
import { client } from './apollo-client';


function App() {
  return (
    <ApolloProvider client={client}>
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
    </ApolloProvider>
  );
}

export default App;
