import { useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { avatarImgs } from "contains/fakeData";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Img1 from "../../images/avatar/avatar6.png";
import Img2 from "../../images/avatar/avatar7.png";
import Img3 from "../../images/avatar/avatar8.png";
import Img4 from "../../images/avatar/avatar9.png";
import Img5 from "../../images/avatar/avatar10.png";
import Img6 from "../../images/avatar/avatar11.png";
import Img7 from "../../images/avatar/avatar12.png";
import Img8 from "../../images/avatar/avatar13.png";
import Img9 from "../../images/avatar/avatar14.png";
import Axios from "axios";
import { UserGlobalContext } from "user";
import { gql, useQuery } from "@apollo/client";
import { profile } from "console";

const GET_PROFILE = gql`
  query GetProfile($username: String!) {
    getProfile(username: $username) {
      id
      name
      email
      selectedAvatar
    }
  }
`;

export default function AvatarDropdown() {
  const items = [
    {
      label: "Avatar-01",
      value: "1",
    },
    {
      label: "Avatar-02",
      value: "2",
    },
    {
      label: "Avatar-03",
      value: "3",
    },
    {
      label: "Avatar-04",
      value: "4",
    },
    {
      label: "Avatar-05",
      value: "5",
    },
    {
      label: "Avatar-06",
      value: "6",
    },
    {
      label: "Avatar-07",
      value: "7",
    },
    {
      label: "Avatar-08",
      value: "8",
    },
    {
      label: "Avatar-09",
      value: "9",
    },
  ];
  const [selectedClient, setSelectedClient] = useState("0");
  const [username, setUsername] = useState("Username");
  const [id, setId] = useState("Not Available");
  const [url, setUrl] = useState(Img1);
  const { LoggedIn, changeLogIn, userProfile } = UserGlobalContext();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(GET_PROFILE, {
    variables: { username: userProfile },
  });

  useEffect(() => {
    if (selectedClient === "1") {
      setUrl(Img1);
    }
    if (selectedClient === "2") {
      setUrl(Img2);
    }
    if (selectedClient === "3") {
      setUrl(Img3);
    }
    if (selectedClient === "4") {
      setUrl(Img4);
    }
    if (selectedClient === "5") {
      setUrl(Img5);
    }
    if (selectedClient === "6") {
      setUrl(Img6);
    }
    if (selectedClient === "7") {
      setUrl(Img7);
    }
    if (selectedClient === "8") {
      setUrl(Img8);
    }
    if (selectedClient === "9") {
      setUrl(Img9);
    }
  }, [selectedClient]);
  // useEffect(() => {
  //   Axios.get("http://localhost:5000/metadata", { withCredentials: true })
  //     .then((response) => {
  //       if (response.data != null) {
  // setSelectedClient(response.data.pic);
  // setUsername(response.data.name);
  // setId(response.data.wallet_address.slice(0, 10) + "...");
  //       }
  //     })
  //     .catch((err) => console.log(err));

  // }, [selectedClient]);
  useEffect(() => {
    if (data) {
      const profile = data.getProfile;
      setSelectedClient(profile.selectedAvatar);
      setUsername(profile.email);
      setId(profile.id.slice(0, 10) + "...");
    }
  }, [data]);

  function handleLogout() {
    changeLogIn(false);
    navigate("/");
  }

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              {/* <Avatar
                imgUrl={avatarImgs[7]}
                sizeClass="w-8 h-8 sm:w-9 sm:h-9"
              /> */}
              <div className="relative rounded-full overflow-hidden flex">
                {/* <Avatar sizeclassName="w-32 h-32" /> */}

                <img className="w-8 h-8 sm:w-9 sm:h-9" src={url}></img>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-3 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid grid-cols-1 gap-6 bg-white dark:bg-neutral-800 py-7 px-6">
                    <div className="flex items-center space-x-3 ">
                      {/* <Avatar imgUrl={avatarImgs[7]} sizeClass="w-12 h-12" /> */}
                      <img className="w-12 h-12 rounded-full" src={url}></img>

                      <div className="flex-grow">
                        <h4 className="font-semibold">{username}</h4>
                        <p className="text-xs mt-0.5">{id}</p>
                      </div>
                    </div>

                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />

                    {/* ------------------ 1 --------------------- */}

                    {/* ------------------ 2 --------------------- */}
                    <Link
                      to={"/personal-domain"}
                      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.67004 18.9501L7.60004 15.6401C8.39004 15.1101 9.53004 15.1701 10.24 15.7801L10.57 16.0701C11.35 16.7401 12.61 16.7401 13.39 16.0701L17.55 12.5001C18.33 11.8301 19.59 11.8301 20.37 12.5001L22 13.9001"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium ">
                          {"Personal Domains"}
                        </p>
                      </div>
                    </Link>

                    {/* ------------------ 2 --------------------- */}
                    <Link
                      to={"/account"}
                      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.2101 15.74L15.67 19.2801C15.53 19.4201 15.4 19.68 15.37 19.87L15.18 21.22C15.11 21.71 15.45 22.05 15.94 21.98L17.29 21.79C17.48 21.76 17.75 21.63 17.88 21.49L21.42 17.95C22.03 17.34 22.32 16.63 21.42 15.73C20.53 14.84 19.8201 15.13 19.2101 15.74Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.7001 16.25C19.0001 17.33 19.84 18.17 20.92 18.47"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.40991 22C3.40991 18.13 7.25994 15 11.9999 15C13.0399 15 14.0399 15.15 14.9699 15.43"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium ">{"Edit profile"}</p>
                      </div>
                    </Link>

                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                    <button
                      onClick={handleLogout}
                      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15 12H3.62"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="ml-4">
                        <p className="text-sm font-medium">Log Out</p>
                      </div>
                    </button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
